import { RecordListSearchProvider } from 'contexts/recordListSearch/RecordListSearchProvider';
import { ColumnSortProvider } from 'contexts/recordListSearch/ColumnSortProvider';

export const withRecordListSearchProviders = Component => props => (
  <RecordListSearchProvider>
    <ColumnSortProvider>
      <Component { ...props } />
    </ColumnSortProvider>
  </RecordListSearchProvider>
);

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SovosDialog from 'mosaic-react/sovos-dialog';
import SovosDialogActions from 'mosaic-react/sovos-dialog-actions';
import SovosDialogContent from 'mosaic-react/sovos-dialog-content';
import SovosDialogTitle from 'mosaic-react/sovos-dialog-title';
import SovosButton from 'mosaic-react/sovos-button';
import RadiosListGroup from '../../importData/radiosListGroup/RadiosListGroup';
import { getReprintDialogOptions } from './slidingFormEditorHelper';

const ReprintOptionsDialog = ({
  open,
  formPrinted,
  formModified,
  reprintStatus,
  handleReprintDialog,
  handleReprint
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedReprintOption, setSelectedReprintOption] = useState(null);

  const reprintDialogOptions = getReprintDialogOptions();

  const getDefaultReprintOption = () => {
    const defaultValueIndex = 0;
    return !reprintStatus.IsPendingReprint || (formPrinted && formModified)
      ? reprintDialogOptions.reprintRecord.options[defaultValueIndex].value
      : null;
  };

  useEffect(() => {
    setSelectedReprintOption(getDefaultReprintOption());
  }, []);

  const handleReprintOptionsChange = e => setSelectedReprintOption(e.target.value);

  const handleReprintAction = () => {
    setLoading(true);
    handleReprint(selectedReprintOption);
  };

  const getDialogTitle = () => {
    const { reprintRecord, unmarkRecord, alreadyPrinted } = reprintDialogOptions;

    if (formPrinted && formModified) {
      return alreadyPrinted.title;
    } else {
      return !reprintStatus.IsPendingReprint
        ? reprintRecord.title
        : unmarkRecord.title;
    }
  };

  const getDialogContent = () => {
    const { reprintRecord, unmarkRecord, alreadyPrinted } = reprintDialogOptions;
    let options;

    if (formPrinted && formModified) {
      options = alreadyPrinted.options;
    } else {
      options = !reprintStatus.IsPendingReprint
        ? reprintRecord.options
        : unmarkRecord.options;
    }

    return options.length ?
      (
        <RadiosListGroup
          value={ selectedReprintOption }
          options={ options }
          onChange={ handleReprintOptionsChange }
        />
      ) : null;
  };

  return (
    <SovosDialog
      open={ open }
      width="small"
    >
      <SovosDialogTitle>
        { getDialogTitle() }
      </SovosDialogTitle>
      <SovosDialogContent>
        { getDialogContent() }
      </SovosDialogContent>
      <SovosDialogActions>
        <SovosButton
          variant="text"
          onClick={ handleReprintDialog }
          disabled={ loading }
        >
          Cancel
        </SovosButton>
        <SovosButton
          onClick={ handleReprintAction }
          disabled={ loading }
        >
          { reprintStatus.IsPendingReprint && !formModified ? "Unmark as 'Available to Reprint'" : 'Save' }
        </SovosButton>
      </SovosDialogActions>
    </SovosDialog>
  );
};

ReprintOptionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  formPrinted: PropTypes.bool.isRequired,
  formModified: PropTypes.bool.isRequired,
  reprintStatus: PropTypes.shape({
    IsPendingReprint: PropTypes.bool
  }).isRequired,
  handleReprintDialog: PropTypes.func.isRequired,
  handleReprint: PropTypes.func.isRequired
};

export default ReprintOptionsDialog;
import { Component } from 'react';
import PropTypes from 'prop-types';
import { getLabel } from '../../dataEntryForms/sovos-dynamic-form/helper/labelHelper';
import CurrencyFormat from 'react-currency-format';
import WrapperMuiTextField from './helper/WrapperMuiTextField';
import SovosTooltip from '../../sovos-tooltip/SovosTooltip';
import Validation from './helper/Validation';
import classNames from 'classnames';
import './validationGridTextField/validationGridTextField.scss';
import withStyles from '@mui/styles/withStyles';

import theme from 'mosaic-react/themes/sovos-theme';

const { palette } = theme;

const styles = {
  root: {
    boxSizing: 'border-box',
    '& .MuiInputBase-root': {
      '&:before': {
        content: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      }
    }
  },
  input: {
    height: 50,
    boxSizing: 'border-box',
    padding: '0 10px',
    borderLeft: `1px solid ${palette.divider}`
  }
};

class AmountDifferenceFieldForm extends Component {
  constructor(props) {
    super(props);
    this.objRef = {};
    this.isMaxLengthInvalid = false;
    this.validation = new Validation(this.props.name, this.props.labelValidation, this.props.validation, this.props.mask, this.handleOnValidation);
    this.state = {
      validationErrorMessage: '',
      currencyInternalValue: this.getFixedValue(this.props.value, this.props.mask),
      differenceValue: this.getDifference(this.props.previousValue, this.props.value),
      containerId: `container-${this.props.name}`
    };
  }

  componentDidMount() {
    this.validation.addValidation(this.getInputRef());

    if (this.props.showPreviousDifferentFields) {
      const element = document.getElementById(this.state.containerId);
      const parentElement = element.parentElement;
      if (parentElement.classList.contains('xs-2')){
        parentElement.classList.remove('xs-2');
        parentElement.classList.add('xs-9');
        element.children[0].style.width = '100%';
      }
    }
  }

  componentDidUpdate = prevProps => {
    if (!prevProps.reset && this.props.reset) {
      this.validation.validateComponent(null, this.props.reset, this.isMaxLengthInvalid, (valid, message) => {
        this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
      });
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ currencyInternalValue: parseFloat(this.props.value) });
      if (this.props.enableCallbackWhenSettingValueViaProps) {
        this.props.onChange(null, this.props.value || 0);
      }
    }
  };

  getInputRef = () => {
    if (this.objRef && this.objRef.input) {
      if (this.props.multiLine) {
        return this.objRef.input.refs.input;
      }
      return this.props.mask ? this.objRef.input.inputElement : this.objRef.input;
    }
    return document.getElementById(this.props.name);
  };

  handleOnValidation = (valid, message) => {
    this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
  };

  handleOnInvalid = evt => {
    this.validation.validateComponent(evt, null, this.isMaxLengthInvalid, (valid, message) => {
      this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
    });
    evt.preventDefault();
  };

  validateCurrency = values => {
    let splitDecimal = values.value.split('.');
    const maxLength = this.props.currencyFormat?.[0] || 10;
    return splitDecimal[0].charAt(0) === '-'
      ? splitDecimal[0].length <= maxLength + 1
      : splitDecimal[0].length <= maxLength;
  };

  handleOnChange = (evt, val) => {
    this.setState({
      currencyInternalValue: val.value,
      differenceValue: this.getDifference(this.props.previousValue, val.floatValue)
    }, () => this.props.onChange(null, val.floatValue || 0));
  };

  getDifference = (previous, current) => (current || 0) - (previous || 0);

  getFixedValue = (value, mask) => {
    const fractionDigits = mask === 'SHARES' ? 4 : this.props.currencyFormat?.[1] || 2;
    return parseFloat(value).toFixed(fractionDigits);
  };

  getBoxNameLabel = (boxName, showError, className) => {
    const { name } = this.props;
    return (
      boxName &&
      <label
        className={ classNames('tirTexfield__label-box', { 'tirTexfield__label-error': showError }, className) }
        htmlFor={ name }
      >
        { boxName }
      </label>
    );
  };

  formatValue = () => this.setState(oldState => ({ currencyInternalValue: this.getFixedValue(oldState.currencyInternalValue, this.props.mask) }));

  render = () => {
    const {
      validationErrorMessage,
      differenceValue,
      containerId
    } = this.state;

    const {
      label,
      hideLabel,
      hideLeftLabel,
      mask,
      name,
      validation,
      customClasses,
      showPreviousDifferentFields,
      previousValue,
      readOnly,
      readOnlyUser,
      boxName,
      rightBoxName,
      currencyFormat
    } = this.props;

    const showError = Boolean(validationErrorMessage);
    const decimalScale = currencyFormat?.[1] || 2;

    return (
      <div id={ containerId } className={ classNames('tirTextfieldForm__wrapper', 'amountDifference__wrapper', customClasses.tirTextfieldFormWrapper) }>
        { !hideLabel &&
          <div className='label__container'>
            <div className='label__container-left'>
              { !hideLeftLabel && this.getBoxNameLabel(boxName, showError, 'label__left-box-name') }
              <label
                className={ classNames('tirTexfield__label', showError && 'tirTexfield__label-error', customClasses.tirTexfieldLabel) }
                htmlFor={ name }
              >
                { getLabel(validation?.required, label) }
              </label>
            </div>
            { this.getBoxNameLabel(rightBoxName || boxName, showError) }
          </div>
        }

        <div className={ classNames({ 'amount-field__display': showPreviousDifferentFields }, customClasses.amountDifferentFields) }>
          {
            showPreviousDifferentFields &&
          <div className={ classNames('textField__container', customClasses.amountFieldContainer ) }>
            <CurrencyFormat
              value={ previousValue ? this.getFixedValue(previousValue, mask) : '0.00' }
              thousandSeparator
              prefix='$'
              decimalScale={ decimalScale }
              fixedDecimalScale={ readOnly }
              customInput={ WrapperMuiTextField }
              autoComplete='off'
              disabled={ true }
            />
          </div>
          }

          <div className={ classNames('textField__container', customClasses.amountFieldContainer ) }>
            <SovosTooltip
              id={ name }
              label={ validationErrorMessage }
              show={ showError }
              place='right'
              className='tir-texfield__error'
              effect='solid'
            >
              <CurrencyFormat
                id={ name }
                value={ readOnly ? this.state.currencyInternalValue || 0.00 : this.state.currencyInternalValue || '' }
                thousandSeparator
                prefix='$'
                onBlur={ this.formatValue }
                decimalScale={ decimalScale }
                fixedDecimalScale={ readOnly }
                isAllowed={ this.validateCurrency }
                customInput={ WrapperMuiTextField }
                onValueChange={ values => this.handleOnChange(null, values) }
                autoComplete='off'
                disabled={ readOnlyUser || readOnly }
              />
            </SovosTooltip>
          </div>

          {
            showPreviousDifferentFields &&
          <div className={ classNames('textField__container', customClasses.amountFieldContainer ) }>
            <CurrencyFormat
              value={ this.getFixedValue(differenceValue, mask) }
              thousandSeparator
              prefix='$'
              decimalScale={ decimalScale }
              fixedDecimalScale={ readOnly }
              customInput={ WrapperMuiTextField }
              autoComplete='off'
              disabled={ true }
            />
          </div>
          }
        </div>

      </div>
    );
  };
}

AmountDifferenceFieldForm.propTypes = {
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  hideLabel: PropTypes.bool,
  boxName: PropTypes.string,
  rightBoxName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  previousValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  mask: PropTypes.string,
  multiLine: PropTypes.bool,
  multiLineConfig: PropTypes.shape({
    rows: PropTypes.number.isRequired,
    rowsMax: PropTypes.number.isRequired
  }),
  validation: PropTypes.shape({
    pattern: PropTypes.string,
    message: PropTypes.string,
    validateOn: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
  }),
  reset: PropTypes.bool,
  onValid: PropTypes.func,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool.isRequired,
  customClasses: PropTypes.objectOf(PropTypes.string),
  showPreviousDifferentFields: PropTypes.bool,
  enableCallbackWhenSettingValueViaProps: PropTypes.bool
};

AmountDifferenceFieldForm.defaultProps = {
  boxName: '',
  rightBoxName: '',
  label: null,
  rightLabel: null,
  labelValidation: '',
  hideLabel: false,
  placeholder: '',
  previousValue: 0,
  value: 0,
  mask: '',
  multiLine: false,
  multiLineConfig: {
    rows: 1,
    rowsMax: 4
  },
  onBlur: () => {},
  onKeyDown: null,
  onKeyPress: null,
  validation: null,
  reset: false,
  onValid: () => {},
  customClasses: {},
  showPreviousDifferentFields: false,
  enableCallbackWhenSettingValueViaProps: true,
  readOnlyUser: false
};

export default withStyles(styles)(AmountDifferenceFieldForm);

import { Suspense, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { shape, object, bool, func, array } from 'prop-types';
import { useAuth } from 'react-oidc-context';
import classnames from 'classnames';
import { withSnackbar } from 'enhancers';
import SovosGlobalLayout from 'mosaic-react/sovos-global-layout/SovosGlobalLayout';
import SuspenseLoadingPage from 'shared/suspenseLoadingPage/SuspenseLoadingPage';
import authenticationHelper from 'helpers/authenticationHelper';
import { TIR_ENT_SECURITY_ADMIN, TIR_NAVIGATE_RTT_LANDING_PAGE, TIR_NAVIGATE_WF_LANDING_PAGE, TIR_RTT_USER } from 'helpers/permissionConstants';
import { authRoutes, handleHelpClick } from '../auth/Routes';
import AutomaticLogoutChildProvider from 'contexts/automaticLogout/AutomaticLogoutChildProvider';
import Eula from 'components/eula/EulaConnector';
import ClientInformation from 'components/clientInformation/ClientInformationConnector';
import FullPageLoader from 'enhancers/loaders/FullPageLoader';
import { structureTypes } from 'pages/manageUsersPage/helpers/structuresRolesHelper';
import useAutomaticLogout from 'contexts/automaticLogout/useAutomaticLogout';
import useContextHasPayers from 'helpers/useContextHasPayers';
import DataResetDialog from 'pages/settingsPage/dataResetDialog/DataResetDialog';
import ClientMigrationDialog from 'pages/settingsPage/clientMigrationDialog/ClientMigrationDialog';
import RequeueBackupWithholdingDialog from 'pages/settingsPage/requeueBackupWithholdingDialog/RequeueBackupWithholdingDialog';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import useMenuSettings from 'helpers/useMenuSettings';
import { withRecordListSearchProviders } from 'contexts/recordListSearch/hoc/withRecordListSearchProviders';

import './App.scss';

const getInitialPage = () => {
  if (authenticationHelper.isTIRCorporate()) {
    return 'Home';
  }

  if (authenticationHelper.isTIREnterprise()) {
    return 'Overview';
  }

  return '';
};

const pathWithoutNav = [
  '/new-payer'
];

const App = ({
  actions,
  showErrorSnackbar,
  isFetchingForms,
  isFetchingPermission,
  selectedContext,
  contexts,
  contextActions,
  formMetaInformationActions,
  manageUserActions,
  showSnackbar,
  user
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();

  const { idleAlert } = useAutomaticLogout();

  const { shouldRedirect } = useContextHasPayers();

  const solution = sessionStorage.tirProduct;

  const {
    menuItems,
    dialogs: {
      dataResetDialogOpen,
      toggleDataResetDialog,
      clientMigrationDialogOpen,
      toggleClientMigrationDialog,
      requeueBWhDialogOpen,
      toggleRequeueBWhDialog
    }
  } = useMenuSettings(
    (message, variant) => showSnackbar({ message, variant }),
    message => showErrorSnackbar({ message: getErrorMessage(message) })
  );

  const [layoutKey, setLayoutKey] = useState('');
  const [selectedLink, setSelectedLink] = useState(getInitialPage());
  const [shouldRenderClientInfo, setShouldRenderClientInfo] = useState(true);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (user.userId && auth.user?.profile.email) {
        authenticationHelper.connectThirdParty(user.userId, auth.user.profile.email.split('@')[1]);
      }
    }
  }, [user, auth]);

  useEffect(() => {
    const snackbarMessage = location?.state?.snackbarMessage;
    if (Boolean(snackbarMessage)) {
      showSnackbar({ message: snackbarMessage });
    }
  }, [location]);

  useEffect(() => {
    showSnackbar({
      open: idleAlert,
      message: 'You will be logged out due to inactivity in 5 minutes. Please take action to keep your session alive.',
      variant: 'warning',
      actionLabel: 'OK',
      classes: {
        content: 'tir-sovosSnackbar_automatic-logout__content'
      },
      onClose: () => {}
    });
  }, [idleAlert, showSnackbar]);

  const containerRef = useRef(null);

  useEffect(() => {
    let container = document.querySelector('.client-info-container');
    if (!isFetchingForms && !isFetchingPermission) {
      const mosaicTarget = document.querySelector('.sovosNavigationLinks');
      if (!mosaicTarget) { return; }

      const parent = mosaicTarget.parentNode;
      if (!parent) { return; }

      parent.style.display = 'flex';
      parent.style.flexDirection = 'column';
      parent.style.justifyContent = 'space-between';

      if (!container) {
        container = document.createElement('div');
        container.className = 'client-info-container';
        parent.appendChild(container);
      }
      containerRef.current = container;
      setShouldRenderClientInfo(true);
    }

  }, [isFetchingForms, isFetchingPermission]);

  useEffect(() => {
    if (!isFetchingForms && !isFetchingPermission) {
      const navElement = document.querySelector('.sovosNavigation');

      if (navElement) {
        const observer = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            if (mutation.attributeName === 'class') {
              const classList = mutation.target.classList;
              if (classList.contains('sovosNavigation--minimal')) {
                setShouldRenderClientInfo(false);
              } else {
                setShouldRenderClientInfo(true);
              }
            }
          });
        });

        observer.observe(navElement, { attributes: true });

        return () => observer.disconnect();
      }
    }
  }, [isFetchingForms, isFetchingPermission]);

  const addSelectStateAction = useCallback(route => {
    const { label, subLinks } = route;

    if (location.pathname.includes(route.path)) {
      setSelectedLink(label);
    }

    const handleNavigate = url => {
      if (url !== window.location.pathname) {
        setSelectedLink(label);
        navigate(url);
      }
    };

    return {
      ...route,
      action: subLinks
        ? null
        : () => handleNavigate(`/${ route.path.includes('/*') ? route.path.split('/*')[0] : route.path }`),
      nestedLinks: subLinks
        ? subLinks
          .filter(route => !route.internal)
          .filter(route => authenticationHelper.checkProduct(route.product))
          .filter(authenticationHelper.checkConfigurationsAndPermissions)
          .map(addSelectStateAction)
        : null
    };
  }, [navigate]);

  const getNavLinks = useMemo(() =>
    authRoutes
      .filter(route => route.internalDynamic ? !route.internalDynamic() : !route.internal)
      .filter(route => !(route.type === 'header' && authenticationHelper.checkSomeUserPermissions([TIR_RTT_USER, TIR_NAVIGATE_WF_LANDING_PAGE, TIR_ENT_SECURITY_ADMIN])))
      .filter(route => authenticationHelper.checkProduct(route.product))
      .filter(authenticationHelper.checkConfigurationsAndPermissions)
      .filter(route => (authenticationHelper.isTIRCorporateECommerce() && authenticationHelper.isSubscriptionCancelled() ? route.showSubscriptionCancelled : true))
      .map(addSelectStateAction), [addSelectStateAction]);

  const getSettingsMenuItems = () => menuItems;

  const checkPayers = async () => {
    if (authenticationHelper.isTIREnterprise()) {
      return false;
    }

    try {
      const response = await shouldRedirect();
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const selectNewContext = async context => {
    if (context.structureType === structureTypes.TAXYEAR) {
      const customAttributes = JSON.parse(context.customAttributes);
      contextActions.setContext({
        ...context,
        name: customAttributes.contextType.toUpperCase(),
        taxYear: String(customAttributes.taxYear),
        customAttributes
      });
      formMetaInformationActions.resetMetadata();
      await manageUserActions.fetchContextInfo();
      if (!authenticationHelper.checkAllUserPermissions([TIR_NAVIGATE_RTT_LANDING_PAGE])) {
        manageUserActions.fetchForms();
      }
      setLayoutKey(`${selectedContext.name}-${selectedContext.taxYear}`);
      setSelectedLink(getInitialPage());

      const shouldRedirectToNewPayerPage = await checkPayers();
      const landingPage = authenticationHelper.getProductLandingPage(shouldRedirectToNewPayerPage);
      navigate(landingPage);
    }
  };

  const handleLogout = async () => {
    navigate('/logout');
  };

  const getUserMenuItems = () => {
    if (authenticationHelper.isInternalRole()) {
      return [
        { label: 'Please close the current tab manually', action: () => {} }
      ];
    } else {
      return [
        { label: 'Logout', action: handleLogout },
        { label: 'Co-browse with Support', action: () => acquireIO.max() } //eslint-disable-line no-undef
      ];
    }
  };

  const hideDashboardItems = pathWithoutNav.some(path => path === location.pathname);

  const getNavProps = () => ({
    product: authenticationHelper.getProductName(),
    products: [],
    links: getNavLinks,
    selectedLink: selectedLink,
    initialSelectedLink: '',
    accounts: authenticationHelper.checkAllUserPermissions([TIR_ENT_SECURITY_ADMIN]) ? [] : contexts,
    selectedAccount: selectedContext,
    setAccount: context => selectNewContext(context),
    slotProps: {
      showSettingsButton: !authenticationHelper.checkSomeUserPermissions([TIR_NAVIGATE_RTT_LANDING_PAGE, TIR_NAVIGATE_WF_LANDING_PAGE]),
      showHelpButton: true,
      userMenu: {
        email: auth.user?.profile.email || ' ',
        name: auth.user?.profile.firstName || ' ',
        surname: auth.user?.profile.lastName || ' ',
        items: getUserMenuItems()
      },
      settingsMenu: menuItems?.length ? {
        items: getSettingsMenuItems()
      } : undefined,
      helpButton: {
        onClick: handleHelpClick
      }
    },
    className: classnames({ 'sovosNavigation-disable': hideDashboardItems })
  });

  const renderClientInfo = () => {
    if (!shouldRenderClientInfo || !containerRef.current) {
      return null;
    }

    return createPortal(<ClientInformation />, containerRef.current);
  };

  return (
    <>
      <SovosGlobalLayout
        NavigationProps={ getNavProps() }
        key={ layoutKey }
      >
        <Suspense fallback={ <SuspenseLoadingPage /> }>
          <Outlet />
        </Suspense>
      </SovosGlobalLayout>
      {
        solution && <Eula />
      }
      <DataResetDialog
        toggleDialog={ toggleDataResetDialog }
        modalOpen={ dataResetDialogOpen }
      />
      <ClientMigrationDialog
        toggleDialog={ toggleClientMigrationDialog }
        modalOpen={ clientMigrationDialogOpen }
        onSubmit={ actions.migrateClient }
      />
      <RequeueBackupWithholdingDialog
        toggleDialog={ toggleRequeueBWhDialog }
        modalOpen={ requeueBWhDialogOpen }
      />
      <FullPageLoader show={ isFetchingForms || isFetchingPermission } />
      { renderClientInfo() }
    </>
  );
};

App.propTypes = {
  actions: shape({
    migrateClient: func
  }).isRequired,
  manageUserActions: shape({
    fetchForms: func,
    fetchContextInfo: func
  }).isRequired,
  formMetaInformationActions: shape({
    resetMetadata: func
  }).isRequired,
  selectedContext: object,
  //TODO: After migrating remove user prop from here and the connector.
  user: object.isRequired,
  contexts: array.isRequired,
  isFetchingForms: bool.isRequired,
  isFetchingPermission: bool.isRequired
};

App.defaultProps = {
  selectedContext: undefined
};

const Dashboard = withSnackbar(withRecordListSearchProviders(App));

const DashboardWrapper = props => (
  <AutomaticLogoutChildProvider isSupport={ authenticationHelper.isInternalRole() }>
    <Dashboard { ...props }/>
  </AutomaticLogoutChildProvider>
);

export default DashboardWrapper;

import { createContext, useContext, useState } from 'react';

export const defaultFilters = {
  errorCodeFilter: '',
  payerIdFilter: '',
  originals: true,
  corrections: true,
  modifiedByFilter: ''
};

const SearchContext = createContext();

export const RecordListSearchProvider = ({ children }) => {
  const [globalSearchTerm, setGlobalSearchTerm] = useState(''); // Global term (for backward compatibility)
  const [globalFilters, setGlobalFilters] = useState(defaultFilters); // Global filters
  const [searchStates, setSearchStates] = useState({}); // Per-entity states

  const getSearchState = (entityCode = 'default') =>
    searchStates[entityCode] || { searchTerm: '', filters: { ...defaultFilters } };

  const setSearchTerm = (entityCode, newTerm) => {
    if (!entityCode) {
      setGlobalSearchTerm(newTerm); // Maintain global state
    }
    setSearchStates(prev => ({
      ...prev,
      [entityCode]: {
        ...getSearchState(entityCode),
        searchTerm: newTerm
      }
    }));
  };

  const setFilters = (entityCode, newFilters) => {
    if (!entityCode) {
      setGlobalFilters(newFilters); // Maintain global state
    }
    setSearchStates(prev => ({
      ...prev,
      [entityCode]: {
        ...getSearchState(entityCode),
        filters: { ...newFilters }
      }
    }));
  };

  const resetFilters = entityCode => {
    if (!entityCode) {
      setGlobalFilters(defaultFilters); // Maintain global state
    }
    setSearchStates(prev => ({
      ...prev,
      [entityCode]: {
        searchTerm: '',
        filters: { ...defaultFilters }
      }
    }));
  };

  const value = {
    globalSearchTerm,
    globalFilters,
    setGlobalSearchTerm,
    setGlobalFilters,
    resetGlobalFilters: () => setGlobalFilters(defaultFilters),
    getSearchState,
    setSearchTerm,
    setFilters,
    resetFilters
  };

  return <SearchContext.Provider value={ value }>{ children }</SearchContext.Provider>;
};

export const useRecordListSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useRecordListSearch must be used within a RecordListSearchProvider');
  }

  return entityCode => ({
    searchTerm: entityCode ? context.getSearchState(entityCode).searchTerm : context.globalSearchTerm,
    filters: entityCode ? context.getSearchState(entityCode).filters : context.globalFilters,
    setSearchTerm: newTerm => context.setSearchTerm(entityCode, newTerm),
    setFilters: newFilters => context.setFilters(entityCode, newFilters),
    resetFilters: () => context.resetFilters(entityCode)
  });
};

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appSettings from './appSettings/AppSettingsReducer';
import authentication from './auth/authReducer';
import manageUser from './manageUser/ManageUserReducer';
import notifications from './notifications/NotificationsReducer';
import dynamicForm from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormReducer';
import recordsSummary from './recordsSummary/RecordsSummaryReducer';
import payer from './payer/PayerReducer';
import recipient from './recipient/RecipientReducer';
import nrecipient from './nrecipient/NRecipientReducer';
import contextReducer from './context/contextReducer';
import importMapping from './import/importMapping/ImportMappingReducer';
import wsReducer from 'helpers/webSocket/WsReducer';
import printCard from './printPage/printCard/PrintCardReducer';
import entity from './entities/EntityReducer';
import selectFieldOptions from 'shared/dataEntryForms/sovos-dynamic-form/redux/SelectFieldOptionsReducer';
import formMetaInformation from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationReducer';
import formComments from './formComments/formCommentsReducer';
import formHistory from './formHistory/formHistoryReducer';
import fileManagerFiles from './fileManager/files/FileManagerFilesReducer';
import fileManagerFolders from './fileManager/folders/FileManagerFoldersReducer';
import controlTotals from './printPage/controlTotals/ControlTotalReducer';
import printSteps from './printPage/printSteps/PrintStepsReducer';
import transmittalCard from './transmittal/transmittalCard/TransmittalCardReducer';
import transmittalENT from './transmitENT/TransmittalENTReducer';
import formsUnderFederalLimit from './formsUnderFederalLimit/FormsUnderFederalLimitReducer';
import userAdministration from './userAdministration/UserAdministrationReducer';
import adminPanel from './adminPanel/AdminPanelReducer';
import recordReducer from 'shared/recordSlidingPanel/redux/RecordReducer';
import fieldMapping from 'shared/importMap/fieldMapping/redux/FieldMappingReducer';

export default history => combineReducers({
  router: connectRouter(history),
  authentication,
  manageUser,
  notifications,
  appSettings,
  dynamicForm,
  recordsSummary,
  payer,
  recipient,
  nrecipient,
  contextReducer,
  importMapping,
  wsReducer,
  printCard,
  entity,
  selectFieldOptions,
  formMetaInformation,
  formComments,
  formHistory,
  fileManagerFiles,
  fileManagerFolders,
  controlTotals,
  printSteps,
  transmittalCard,
  transmittalENT,
  formsUnderFederalLimit,
  userAdministration,
  adminPanel,
  recordReducer,
  fieldMapping
});

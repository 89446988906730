import actionTypes from './contextConstants';
import AuthenticationHelper from 'helpers/authenticationHelper';
import { activeTaxYear } from 'helpers/constants';

const userContextData = {
  contexts: [],
  selectedContext: null
};

let userContext;
let userInfo;
let clientInfo;
let contextTree;

try {
  userInfo = JSON.parse(sessionStorage.getItem('USER_INFO')) || {};
  clientInfo = JSON.parse(sessionStorage.getItem('CLIENT_INFO')) || {};
  contextTree = JSON.parse(sessionStorage.getItem('CONTEXT_TREE')) || [];
  userContext = JSON.parse(sessionStorage.getItem('USER_CONTEXT')) || userContextData;
} catch (e) {
  userContext = userContextData;
  userInfo = {};
  clientInfo = {};
}

const initialStore = {
  ...userContext,
  fetchingContexts: false,
  fetchingUser: false,
  user: userInfo,
  fetchingClient: false,
  client: clientInfo,
  fetchingUserContext: false,
  savingUserContext: false,
  contextTree
};

export default function context(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCH_CONTEXTS_SUCCESS:
      const userContext = { ...action.contexts.selectedContext };
      AuthenticationHelper.storeContext(userContext);

      const itemsToStore = {
        contexts: action.contexts.userContexts,
        selectedContext: userContext,
        selectedOrg: userContext
      };

      sessionStorage.setItem('USER_CONTEXT', JSON.stringify(itemsToStore));

      return {
        ...state,
        ...itemsToStore,
        contexts: action.contexts.allOrgs,
        selectedContext: { ...userContext },
        fetchingContexts: false
      };

    case actionTypes.FETCH_CONTEXTS_FAILURE:
      return {
        ...state,
        contexts: [],
        selectedContext: null,
        fetchingContexts: false
      };

    case actionTypes.FETCHING_CONTEXTS:
      return {
        ...state,
        fetchingContexts: true
      };

    case actionTypes.FETCHING_USER:
      return { ...state, fetchingUser: true };

    case actionTypes.FETCH_USER_SUCCESS:
      sessionStorage.setItem('USER_INFO', JSON.stringify(action.userInfo));
      return { ...state, fetchingUser: false, user: action.userInfo };

    case actionTypes.FETCH_USER_FAIL:
      return { ...state, fetchingUser: false };

    case actionTypes.FETCHING_CLIENT:
      return { ...state, fetchingClient: true };

    case actionTypes.FETCH_CLIENT_SUCCESS:
      sessionStorage.setItem('CLIENT_INFO', JSON.stringify(action.clientInfo));
      return { ...state, fetchingClient: false, client: action.clientInfo };

    case actionTypes.FETCH_CLIENT_FAIL:
      return { ...state, fetchingClient: false };

    case actionTypes.SET_CONTEXT_SUCCESS:
      AuthenticationHelper.storeContext(action.context);
      return { ...state, selectedContext: { ...action.context } };

    case actionTypes.SAVE_USER_CONTEXT_SUCCESS:
      sessionStorage.setItem('USER_CONTEXT', JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('USER_CONTEXT')),
        selectedContext: action.context
      }));
      return { ...state };

    case actionTypes.SET_CONTEXT_TREE: {

      const mapNestedAccounts = (nestedAccounts, contextName) =>
        nestedAccounts.map(account => {
          const customAttributes = account.customAttributes ? JSON.parse(account.customAttributes) : {};
          return {
            ...account,
            taxYear: customAttributes.taxYear ? customAttributes.taxYear.toString() : undefined,
            isCurrentYear: customAttributes.taxYear === activeTaxYear,
            name: contextName.trim(),
            type: contextName.toLowerCase() === 'prod' ? 'production' : 'test'
          };
        });

      const contextTree = action.contextTree[0].nestedAccounts.flatMap(context =>
        mapNestedAccounts(context.nestedAccounts, context.displayName)
      );

      const groupedAndSortedContextTree = contextTree.sort((a, b) => {
        if (a.type === 'production' && b.type !== 'production') { return -1; }
        if (a.type !== 'production' && b.type === 'production') { return 1; }

        if (a.taxYear && b.taxYear) {
          return parseInt(b.taxYear) - parseInt(a.taxYear);
        }
        return 0;
      });

      // Store the updated context tree in sessionStorage
      sessionStorage.setItem('CONTEXT_TREE', JSON.stringify(groupedAndSortedContextTree));

      // Update the state with the new structure
      return { ...state, contextTree: groupedAndSortedContextTree };
    }

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    case actionTypes.SET_CONTEXTS:
      return { ...state, contexts: action.contexts };
    default:
      return state;
  }
}

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContextInfo } from 'redux/manageUser/ManageUserActions';
import { usageReport, recalculate } from 'redux/adminPanel/AdminPanelActions';
import authenticationHelper from 'helpers/authenticationHelper';
import {
  S1_C_USER,
  S1_D_USER,
  S1_RESET_USER,
  S1_U_USER,
  TIR_ADMIN,
  TIR_C_USER,
  TIR_D_RECORD_NON_WITHHOLDING,
  TIR_D_RECORD_PAYEE,
  TIR_D_RECORD_PAYER,
  TIR_D_USER,
  TIR_ENT,
  TIR_FULL_INTERNAL_ADMIN,
  TIR_FULL_INTERNAL_DEVELOPER,
  TIR_RESET_USER,
  TIR_U_SUBSCRIPTION,
  TIR_U_USER
} from 'helpers/permissionConstants';
import migrationResultCodes from 'pages/settingsPage/migrationResultCodes';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { BE_TAX_IDENTITY_100076, BE_TAX_IDENTITY_100088 } from 'helpers/errorMessageConstants';
import { productTypeCode } from 'helpers/enums';
import { getChargebeeInstance } from 'pages/loginOtp/chargebeeHelp';

const useMenuSettings = (showMessage, onError) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    migrationResult,
    migratingClient,
    recalculatingStatusStarted,
    recalculatingStatusSuccess,
    recalculatingStatusFailed,
    calculatingBWStatusStarted,
    calculatingBWStatusSuccess,
    calculatingBWStatusFailed
  ] = useSelector(state => ([
    state.adminPanel.migrationResult,
    state.adminPanel.migratingClient,
    state.adminPanel.recalculatingStatusStarted,
    state.adminPanel.recalculatingStatusSuccess,
    state.adminPanel.recalculatingStatusFailed,
    state.adminPanel.calculatingBWStatusStarted,
    state.adminPanel.calculatingBWStatusSuccess,
    state.adminPanel.calculatingBWStatusFailed
  ]));

  const [dataResetDialogOpen, setDataResetDialogOpen] = useState(false);
  const [clientMigrationDialogOpen, setClientMigrationDialogOpen] = useState(false);
  const [requeueBWhDialogOpen, setRequeueBWhDialogOpen] = useState(false);

  const toggleDialog = useCallback(setter => () => setter(prev => !prev), []);

  const [previousMigratingClient, setPreviousMigratingClient] = useState(migratingClient);
  const [previousRecalculatingStatusStarted, setPreviousRecalculatingStatusStarted] = useState(recalculatingStatusStarted);
  const [previousRecalculatingStatusSuccess, setPreviousRecalculatingStatusSuccess] = useState(recalculatingStatusSuccess);
  const [previousRecalculatingStatusFailed, setPreviousRecalculatingStatusFailed] = useState(recalculatingStatusFailed);
  const [previousCalculatingBWStatusStarted, setPreviousCalculatingBWStatusStarted] = useState(calculatingBWStatusStarted);
  const [previousCalculatingBWStatusSuccess, setPreviousCalculatingBWStatusSuccess] = useState(calculatingBWStatusSuccess);
  const [previousCalculatingBWStatusFailed, setPreviousCalculatingBWStatusFailed] = useState(calculatingBWStatusFailed);

  useEffect(() => {
    if (previousMigratingClient && !migratingClient && migrationResult > 0) {
      showMessage(migrationResultCodes[migrationResult].snackbarMessage, migrationResultCodes[migrationResult].snackBarType || 'confirmation');
    }

    setPreviousMigratingClient(migratingClient);
  }, [migratingClient, migrationResult]);

  useEffect(() => {
    if (!previousRecalculatingStatusStarted && recalculatingStatusStarted) {
      showMessage('Recalculate status started');
    }

    setPreviousRecalculatingStatusStarted(recalculatingStatusStarted);
  }, [recalculatingStatusStarted]);

  useEffect(() => {
    if (!previousRecalculatingStatusSuccess && recalculatingStatusSuccess) {
      showMessage('Recalculate status finished successful');
    }

    setPreviousRecalculatingStatusSuccess(recalculatingStatusSuccess);
  }, [recalculatingStatusSuccess]);

  useEffect(() => {
    if (!previousRecalculatingStatusFailed && recalculatingStatusFailed) {
      onError(getErrorMessage({ data: { code: BE_TAX_IDENTITY_100076 } }, null));
    }

    setPreviousRecalculatingStatusFailed(recalculatingStatusFailed);
  }, [recalculatingStatusFailed]);

  useEffect(() => {
    if (!previousCalculatingBWStatusStarted && calculatingBWStatusStarted) {
      showMessage('Calculate status started');
    }

    setPreviousCalculatingBWStatusStarted(calculatingBWStatusStarted);
  }, [calculatingBWStatusStarted]);

  useEffect(() => {
    if (!previousCalculatingBWStatusSuccess && calculatingBWStatusSuccess) {
      showMessage('Calculate status finished successful');
    }

    setPreviousCalculatingBWStatusSuccess(calculatingBWStatusSuccess);
  }, [calculatingBWStatusSuccess]);

  useEffect(() => {
    if (!previousCalculatingBWStatusFailed && calculatingBWStatusFailed) {
      onError(getErrorMessage({ data: { code: BE_TAX_IDENTITY_100088 } }, null));
    }

    setPreviousCalculatingBWStatusFailed(calculatingBWStatusFailed);
  }, [calculatingBWStatusFailed]);

  const canView = permissions => authenticationHelper.checkSomeUserPermissions(permissions);

  const menuItems = [
    canView([S1_C_USER, S1_U_USER, S1_D_USER, S1_RESET_USER, TIR_C_USER, TIR_U_USER, TIR_D_USER, TIR_RESET_USER]) && {
      label: 'Users',
      action: () => navigate('/manage-users')
    },
    canView([TIR_FULL_INTERNAL_ADMIN, TIR_FULL_INTERNAL_DEVELOPER]) && {
      label: 'Refresh permissions',
      action: dispatch(fetchContextInfo)
    },
    canView([TIR_ADMIN]) && {
      label: 'Tax year management',
      action: () => navigate('/new-tax-year')
    },
    canView([TIR_D_RECORD_PAYER, TIR_D_RECORD_PAYEE, TIR_D_RECORD_NON_WITHHOLDING]) && {
      label: 'Data reset',
      action: toggleDialog(setDataResetDialogOpen)
    },
    canView([TIR_FULL_INTERNAL_ADMIN, TIR_FULL_INTERNAL_DEVELOPER]) && {
      label: 'Client migration',
      action: toggleDialog(setClientMigrationDialogOpen)
    },
    canView([TIR_FULL_INTERNAL_ADMIN, TIR_FULL_INTERNAL_DEVELOPER]) && {
      label: 'Client usage report',
      action: dispatch(usageReport)
    },
    canView([TIR_ADMIN]) && {
      label: 'Solicitation status recalculation',
      action: dispatch(recalculate)
    },
    (canView([TIR_FULL_INTERNAL_ADMIN, TIR_FULL_INTERNAL_DEVELOPER]) && authenticationHelper.checkProduct([productTypeCode.TIRE])) && {
      label: 'Requeue backup withholding process',
      action: toggleDialog(setRequeueBWhDialogOpen)
    },
    (authenticationHelper.checkProduct([productTypeCode.TIRC])) && {
      label: 'Recipient grouping',
      action: () => navigate('/tax-identity-management/recipient-grouping')
    },
    (canView([TIR_U_SUBSCRIPTION]) && authenticationHelper.isTIRCorporateECommerce()) && !authenticationHelper.isInternalRole() && {
      label: 'Subscription management',
      action: async () => {
        try {
          const cbPortal = getChargebeeInstance().createChargebeePortal();
          cbPortal.open({
            close() {
              getChargebeeInstance().logout();
            }
          });
        } catch (err) {
          onError(err);
        }
      }
    },
    (canView([TIR_ENT]) && authenticationHelper.isTIREnterprise() && authenticationHelper.isRoleDev()) && {
      label: 'Roles management',
      action: () => navigate(`/custom-roles/${authenticationHelper.getClientId()}/list`)
    }
  ].filter(Boolean);

  return {
    menuItems,
    dialogs: {
      dataResetDialogOpen,
      toggleDataResetDialog: toggleDialog(setDataResetDialogOpen),
      clientMigrationDialogOpen,
      toggleClientMigrationDialog: toggleDialog(setClientMigrationDialogOpen),
      requeueBWhDialogOpen,
      toggleRequeueBWhDialog: toggleDialog(setRequeueBWhDialogOpen)
    }
  };
};

export default useMenuSettings;
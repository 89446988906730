import { isNoticeType } from 'pages/printCorp/helpers/notice/NoticeConstants';
import { getRecordName } from 'helpers/formConstants';
import { getLocalTimestamp } from 'tir-frontend-common/helpers/dateHelper';
import { replaceStatusWording } from 'helpers/statusWordingHelper';

const columns = [
  {
    title: 'Print #',
    id: '1',
    width: 72,
    sortIndicator: 'PrintNo',
    dataKey: 'PrintNo'
  },
  {
    title: 'Form type',
    id: '2',
    width: 88,
    sortIndicator: 'FormType',
    component: ({ content }) => getRecordName(content.FormType)
  },
  {
    title: 'Filing type',
    id: '3',
    width: 72,
    sortIndicator: 'FilingType',
    component: ({ content }) => isNoticeType(content.FormType)
      ? ''
      : content.FilingType
  },
  {
    title: 'Forms',
    id: '4',
    width: 72,
    align: 'right',
    sortIndicator: 'Forms',
    dataKey: 'Forms'
  },
  {
    title: 'Payers',
    id: '5',
    width: 72,
    align: 'right',
    sortIndicator: 'Payers',
    component: ({ content }) => isNoticeType(content.FormType)
      ? ''
      : content.Payers
  },
  {
    title: 'Print status',
    id: '6',
    width: 'greedy',
    sortIndicator: 'PrintStatus',
    component: ({ content }) => `${replaceStatusWording(content.PrintStatus)} ${getLocalTimestamp(content.UpdatedOn)}`
  }
];

export {
  columns
};

import TinMask from 'shared/dataModifier/tinMask/TinMask';
import { the1042SFchar } from './formConstants';

const failedPayerColumns = [
  {
    title: 'TIN',
    id: 1,
    width: 'greedy',
    dataKey: 'Pein'
  },
  {
    title: 'Payer ID',
    id: 2,
    width: 'greedy',
    dataKey: 'Ptin'
  },
  {
    title: 'Name',
    id: 3,
    width: 'currency',
    dataKey: 'Pname'
  },
  {
    title: 'Description',
    id: 4,
    width: 'greedy',
    dataKey: 'ErrorMsg'
  }
];

const failedRecipientColumns = [
  {
    title: 'TIN',
    id: 1,
    type: 'currency',
    style: { flexGrow: 1, flexShrink: 0 },
    component: ({ content }) => (
      content.Tin
        ? <TinMask records={ { TIN: content.Tin, TINTYPE: content.TinType } } />
        : ""
    )
  },
  {
    title: 'Account Number',
    id: 2,
    type: 'currency',
    style: { flexGrow: 1, flexShrink: 0 },
    dataKey: 'AccountNo'
  },
  {
    title: 'Name',
    id: 3,
    type: 'currency',
    style: { flexGrow: 1, flexShrink: 0 },
    dataKey: 'Name'
  },
  {
    title: 'Description',
    id: 4,
    type: 'currency',
    width: 'greedy',
    dataKey: 'ErrorMsg'
  }
];

const failedFormColumns = taxEntityType => [
  {
    title: 'TIN',
    id: 1,
    type: 'currency',
    width: 120,
    component: ({ content }) => {
      if (taxEntityType === the1042SFchar) {
        return (
          content.Fk2
            ? <TinMask records={ { TIN: content.Fk2, TINTYPE: content.TinType } } />
            : ""
        );
      } else {
        return (
          content.Fk1
            ? <TinMask records={ { TIN: content.Fk1, TINTYPE: content.TinType } } />
            : ""
        );
      }
    }
  },
  {
    title: 'Account number',
    id: 2,
    type: 'currency',
    width: 120,
    component: ({ content }) => taxEntityType === the1042SFchar ? content.Fk1 : content.Fk2
  },
  {
    title: 'Payer ID',
    id: 3,
    type: 'currency',
    width: 120,
    dataKey: 'PayerId'
  },
  {
    title: 'Description',
    id: 4,
    type: 'currency',
    width: 'greedy',
    dataKey: `ErrorMsg`
  }
];

export const getFailedHeaders = taxEntityType => {
  switch (taxEntityType) {
    case '0':
      return failedPayerColumns;
    case '1':
    case 'N':
      return failedRecipientColumns;
    default:
      return failedFormColumns(taxEntityType);
  }
};
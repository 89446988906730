import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import { downloadFileByPathNext } from 'helpers/fileTransferHelper';
import { FE_FILEMANAGER_001 } from 'helpers/errorMessageConstants';
import { getErrorMessage } from 'helpers/errorMessageHelper';

const download = (response, showErrorSnackbar, fullFilePath) => {
  const filePath = fullFilePath
    ? fullFilePath
    : response.data.data
      ? response.data.data
      : response.data.ReportName;
  const filePathParts = filePath.split('\\');
  const file = {
    path: filePath,
    name: filePathParts[filePathParts.length - 1]
  };
  downloadFileByPathNext({
    file,
    onFail: () => showErrorSnackbar({ message: getErrorMessage(null, FE_FILEMANAGER_001) })
  });
};

const downloadReport = async (body, showErrorSnackbar, config = { url: '/reports' }) => {
  const response = await post(config.url, body);
  download(response, showErrorSnackbar);
};

const downloadCannedReport = async (fchar, showErrorSnackbar, config = { url: '/cannedreports/forms' }) => {
  const response = await post(`${config.url}/${fchar}`, {});
  download(response, showErrorSnackbar);
};

const downloadW2Report = async (fromDate, toDate, showErrorSnackbar) => {
  const response = await get(`/reports/W2/audit-report?fromDate=${ fromDate.replace(/\//g, '') }&toDate=${ toDate.replace(/\//g, '') }`);
  download(response, showErrorSnackbar);
};

const downloadW2MReport = async (payload, showErrorSnackbar) => {
  const response = await post('/reports/W2/audit-report', payload);
  download(response, showErrorSnackbar);
};

const downloadW2MTotalsReport = async (payload, showErrorSnackbar) => {
  const response = await post('/reports/W2/total-report', payload);
  download(response, showErrorSnackbar);
};

const fetchBlobAndDownload = async (
  request,
  fileName,
  onComplete = () => null,
  onError = () => null
) => {
  const downloadBlobData = (fileData, fileName) => {
    const blob = new Blob([fileData], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    document.body.appendChild(link);
    link.style = "display: none";
    link.href = url;
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(url);
  };

  try {
    const response = await request();
    downloadBlobData(response.data, fileName);
    onComplete();
  } catch (error) {
    console.log(error);
    onError(error);
  }
};

export {
  downloadReport,
  downloadCannedReport,
  downloadW2Report,
  downloadW2MReport,
  downloadW2MTotalsReport,
  download,
  fetchBlobAndDownload
};
import jwtDecode from 'jwt-decode';
import { getRequestIdTimestamp } from 'tir-frontend-common/helpers/dateHelper';
import { productType as product } from 'helpers/enums';
import pendoInitialize from 'pages/loginOtp/pendo.js';
import { includeZendesk } from 'pages/loginOtp/zendesk.js';
import { includeChargebee } from 'pages/loginOtp/chargebeeHelp.js';
import { post } from 'helpers/axios/axiosInterceptorsV1';
import {
  TIR_DEVELOPER,
  TIR_FULL_INTERNAL_ADMIN,
  TIR_FULL_INTERNAL_DEVELOPER,
  TIR_U_RECORD_PAYER,
  TIR_U_RECORD_PAYEE,
  TIR_U_RECORD_NON_WITHHOLDING,
  TIR_U_RECORD_NON_TAX_FIELDS,
  TIR_CORP,
  TIR_ENT,
  TIR_ENT_SECURITY_ADMIN,
  TIR_NAVIGATE_RTT_LANDING_PAGE,
  TIR_NAVIGATE_WF_LANDING_PAGE,
  TIR_U_SUBSCRIPTION
} from 'helpers/permissionConstants';

const SOVOS_IDENTITY_LOGIN = 'SOVOS_IDENTITY_LOGIN';
const TOKEN = 'token';
const ID_TOKEN = 'id_token';

const paths = {
  CLIENT_ORGS: '/iam/users/clients/orgs',
  OTP_LOGIN: '/otp-login'
};

const headerTypes = {
  CONTENT_TYPE: 'Content-Type'
};

class AuthenticationHelper {
  isJwtTokenStored() {
    return Boolean(localStorage.token);
  }

  getHeaders(config) {
    const context = this.getContext();
    let headers = {
      Authorization:
        this.isPathIncluded(config?.url, paths.OTP_LOGIN)
          ? this.getOtpToken()
          : this.isJwtTokenStored()
            ? `Bearer ${this.getJwtToken()}`
            : '',
      //TODO: Research and apply solution for webpack build (options: workaround)
      ['X-Taxyear']: context ? this.getTaxYear() : '', //eslint-disable-line no-useless-computed-key
      ['X-Context-Name']: context ? this.getContextName() : '', //eslint-disable-line no-useless-computed-key
      ['x-request-id']: context ? `${context.id}+${getRequestIdTimestamp()}` : getRequestIdTimestamp(), //eslint-disable-line no-useless-computed-key
      //['X-TokenExchangeFeatureFlag-Override']: sessionStorage.getItem(SOVOS_IDENTITY_LOGIN) //eslint-disable-line no-useless-computed-key
      // TODO check the value
      ['X-TokenExchangeFeatureFlag-Override']: true, //eslint-disable-line no-useless-computed-key
      ['x-s1replacementfeatureflag-override']: true //eslint-disable-line no-useless-computed-key
    };

    if (sessionStorage.tirProduct && !this.isPathIncluded(config?.url, paths.CLIENT_ORGS)) {
      headers['x-app-id'] = sessionStorage.tirProduct;
    }

    if (this.isContextStored()) {
      headers['x-request-context'] = this.getContext().id;
    } else if (this.getOrgId()) {
      headers['x-request-context'] = this.getOrgId();
    }

    if (config?.customHeaders) {
      headers = { ...headers, ...config?.customHeaders };
    }

    return headers;
  }

  getContextName() {
    const context = this.getContext();
    return context ?
      context.customAttributes.contextType ?
        context.customAttributes.contextType.toUpperCase() :
        context.customAttributes.parentName.toUpperCase()
      : null;
  }

  getJwtToken() {
    return this.isJwtTokenStored() ? localStorage.token : null;
  }

  getIdToken() {
    return 'Bearer ' + localStorage.getItem(ID_TOKEN);
  }

  getJwtTokenAuthHeader() {
    return 'Bearer ' + this.getJwtToken();
  }

  storeJwtToken(jwtToken, sovosIdentityToken = 'false') {
    if (jwtToken.indexOf('Bearer ') >= 0) {
      jwtToken = jwtToken.replace('Bearer ', '');
    }
    localStorage.setItem(TOKEN, jwtToken);
    sessionStorage.setItem(SOVOS_IDENTITY_LOGIN, sovosIdentityToken);
    return null;
  }

  storeIdToken(idToken) {
    localStorage.setItem(ID_TOKEN, idToken);
    return null;
  }

  removeJwtToken() {
    localStorage.removeItem(TOKEN);
    return null;
  }

  hasJwtPayloadKey(key) {
    if (this.isJwtTokenStored()) {
      const decodedToken = jwtDecode(localStorage.token);
      if (!key) {
        return null;
      }
      return decodedToken[key] !== undefined;
    }
    return null;
  }

  getValueFromJwtPayload(key) {
    if (this.isJwtTokenStored) {
      const decodedToken = jwtDecode(localStorage.token);
      if (!key) {
        return null;
      }
      return decodedToken[key];
    }
    return null;
  }

  getDecodedToken() {
    return (this.isJwtTokenStored) ? jwtDecode(localStorage.token) : null;
  }

  storeOtpToken(otpToken) {
    sessionStorage.setItem('otpToken', otpToken);
    return null;
  }

  getOtpToken() {
    return 'Bearer ' + sessionStorage.otpToken;
  }

  storeOrgId(orgId) {
    sessionStorage.setItem('orgId', orgId);
    return null;
  }

  getOrgId() {
    return sessionStorage.orgId;
  }

  getClientId() {
    return this.getContext().clientId;
  }

  getClientInfo() {
    try {
      return JSON.parse(sessionStorage.CLIENT_INFO);
    } catch (error) {
      return null;
    }
  }

  isEulaStored() {
    return Boolean(sessionStorage.eulaAccepted);
  }

  getEulaStored() {
    return Number(sessionStorage.eulaAccepted);
  }

  storeContext(context) {
    sessionStorage.setItem('context', JSON.stringify(context));
    return null;
  }

  isContextStored() {
    return (sessionStorage.context && sessionStorage.context !== '');
  }

  getContext() {
    return this.isContextStored() ? JSON.parse(sessionStorage.context) : null;
  }

  isTestContext() {
    return this.getContext().customAttributes.contextType === 'TEST';
  }

  getAllContexts() {
    return sessionStorage.USER_CONTEXT ? JSON.parse(sessionStorage.USER_CONTEXT).contexts : null;
  }

  isTIRCorporate() {
    return sessionStorage.tirProduct === 'TIRC';
  }

  isTIREnterprise() {
    return sessionStorage.tirProduct === 'TIRE';
  }

  isTIRCorporateECommerce () {
    try {
      return JSON.parse(sessionStorage.CLIENT_INFO)?.CIdType === '2';
    } catch (error) {
      return false;
    }
  }

  storeIsFreeTrial(isFreeTrial) {
    sessionStorage.setItem('isFreeTrial', isFreeTrial);
  }

  isFreeTrial() {
    return JSON.parse(sessionStorage.getItem('isFreeTrial'));
  }

  storeIsSubscriptionCancelled(isSubscriptionCancelled) {
    sessionStorage.setItem('isSubscriptionCancelled', isSubscriptionCancelled);
  }

  isSubscriptionCancelled() {
    return JSON.parse(sessionStorage.getItem('isSubscriptionCancelled'));
  }

  getProductLandingPage(redirectToNewPayerPage) {
    if (this.checkAllUserPermissions(TIR_ENT_SECURITY_ADMIN)) {
      return '/manage-users';
    }

    if (this.checkAllUserPermissions(TIR_NAVIGATE_RTT_LANDING_PAGE)) {
      return '/realtimetin';
    }

    if (this.isTIRCorporate()) {
      if (this.isTIRCorporateECommerce() && this.isSubscriptionCancelled()) {
        return '/manage-files';
      }

      if (redirectToNewPayerPage) {
        return '/new-payer';
      };

      if (this.checkAllUserPermissions(TIR_NAVIGATE_WF_LANDING_PAGE)) {
        return '/payers';
      }

      return '/home';
    }

    if (this.isTIREnterprise()) {
      if (this.checkAllUserPermissions(TIR_NAVIGATE_WF_LANDING_PAGE)) {
        return '/data/records';
      }

      return '/overview';
    }
    return '/logout';
  }

  getTaxYear() {
    const context = this.getContext();
    return context ? context.customAttributes.taxYear : null;
  }

  verifyFieldProductMatch(permissions) {
    if (!permissions || (!permissions.includes(TIR_CORP) && !permissions.includes(TIR_ENT))) {
      return true;
    } else {
      return permissions.some(permission => ((permission === TIR_CORP && this.isTIRCorporate()) || (permission === TIR_ENT && this.isTIREnterprise())));
    }
  }

  getProductName() {
    let productTitle = 'Sovos - TIR';

    if (sessionStorage.tirProduct === 'TIRC') {
      productTitle = this.isTIRCorporateECommerce() ? product.TIRC_ECOMMERCE : product.TIRC;
    } else if (sessionStorage.tirProduct === 'TIRE') {
      productTitle = product.TIRE;
    }

    document.title = productTitle;
    return productTitle;
  }

  getShortProductName() {
    return sessionStorage.tirProduct;
  }

  getPendoShortProductName() {
    const eCommerceProductShortName = '1099P';
    return this.isTIRCorporateECommerce() ? eCommerceProductShortName : sessionStorage.tirProduct;
  }

  async connectThirdParty(userId, userDomain) {
    const pendoInfo = {
      //TODO: check how we will handle this in case of sovos identity, maybe we already have this info
      userId: userId,
      domain: userDomain,
      clientId: this.getClientId(),
      taxYear: this.getTaxYear(),
      product: this.getPendoShortProductName(),
      accountName: sessionStorage.getItem('CLIENT_INFO').Name
    };

    pendoInitialize(pendoInfo);

    try {
      const response = await post('environment/tokens/helpdesk');
      includeZendesk(response.data);
    } catch (error) {
      console.error(error);
    }

    if (this.checkSomeUserPermissions([TIR_U_SUBSCRIPTION])) {
      includeChargebee();
    }
  };

  // takes an array of configurations, returns an array of Booleans or null
  // true if the configuration's value is truthy
  // false if a configuration's value is falsy or if the configuration is not found
  // null if there is an exception
  checkConfiguration(configurations) {
    return configurations.map(configName => {
      const configuration =
        sessionStorage.solutionConfigurations &&
        JSON.parse(sessionStorage.solutionConfigurations).find(
          config => config.Name === configName
        );
      if (configuration) {
        try {
          return Boolean(JSON.parse(configuration.Value.toLowerCase()));
        } catch (e) {
          return null;
        }
      }
      return false;
    });
  }

  // takes an array and runs through checkConfigurations
  // returns true if all items in the array returned by checkConfigurations are true, else false
  // returns true if nothing is passed as an argument
  checkAllConfigurations = (allConfigurationsNeeded = []) => {
    const configBooleans = this.checkConfiguration(allConfigurationsNeeded);
    return configBooleans.every(config => config);
  };

  // takes an array and runs through checkConfigurations
  // returns true if any items in the array returned by checkConfigurations are true, else false
  // returns true if nothing is passed as an argument
  checkSomeConfigurations = (someConfigurationsNeeded = null) => {
    if (!someConfigurationsNeeded) {
      return true;
    }
    const configBooleans = this.checkConfiguration(someConfigurationsNeeded);
    return configBooleans.some(config => config);
  };

  getConfigurationValue(configName) {
    try {
      return sessionStorage.solutionConfigurations &&
        JSON.parse(JSON.parse(sessionStorage.solutionConfigurations).find(
          config => config.Name === configName
        ).Value);
    } catch (e) {
      return null;
    }
  }

  isPathIncluded(url, path) {
    return url?.includes(path);
  }

  checkUserPermissions(permissions = null, type) {
    const userPermissions = JSON.parse(sessionStorage.getItem('userPermissions'));
    if (!userPermissions) {
      return false;
    }
    if (!permissions) {
      return true;
    }
    if (Array.isArray(permissions)) {
      return type === 'ANY'
        ? userPermissions.some(permission => permissions.includes(permission))
        : permissions.every(permission => userPermissions.includes(permission));
    } else {
      return userPermissions.some(permission => permissions === permission);
    }
  }

  checkAllUserPermissions(permissions) {
    return this.checkUserPermissions(permissions, 'ALL');
  }

  checkSomeUserPermissions(permissions) {
    return this.checkUserPermissions(permissions, 'ANY');
  }

  blockForSomeUserPermissions(permissions) {
    return permissions ? !this.checkUserPermissions(permissions, 'ANY') : true;
  }

  getContentTypeHeader() {
    return { [headerTypes.CONTENT_TYPE]: 'application/json; charset=utf-8' };
  }

  isRoleDev() {
    return this.checkAllUserPermissions([TIR_DEVELOPER]);
  }

  isInternalRole() {
    return this.checkSomeUserPermissions([TIR_FULL_INTERNAL_ADMIN, TIR_FULL_INTERNAL_DEVELOPER]);
  }

  canEditOnlyNonTaxFields() {
    const canUpdateRecord = this.checkSomeUserPermissions([TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING]);
    const canUpdateNonTaxFields = this.checkAllUserPermissions([TIR_U_RECORD_NON_TAX_FIELDS]);
    return canUpdateNonTaxFields && !canUpdateRecord;
  }

  checkConfigurationsAndPermissions = route => (
    this.checkSomeUserPermissions(route.somePermissionsNeeded) &&
    this.checkAllUserPermissions(route.allPermissionsNeeded) &&
    this.blockForSomeUserPermissions(route.somePermissionsBlocked) &&
    this.checkAllConfigurations(route.allConfigurationsNeeded) &&
    this.checkSomeConfigurations(route.someConfigurationsNeeded) &&
    this.checkSomeConfigurations(route.someDynamicConfigurationsNeeded ? route.someDynamicConfigurationsNeeded() : null)
  );

  getIsMetadataFieldAllowed = (metadataField = {}) => (
    this.verifyFieldProductMatch(metadataField.permissions) &&
    this.checkAllUserPermissions(metadataField.permissions) &&
    this.checkAllConfigurations(metadataField.allConfigurationsNeeded) &&
    this.checkSomeConfigurations(metadataField.someConfigurationsNeeded)
  );

  checkProduct = products => products ? products.some(product => product === sessionStorage.getItem('tirProduct')) : true;
}

export default new AuthenticationHelper();

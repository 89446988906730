import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextFieldForm from 'shared/standardInput/textField/TextFieldFormConnector';
import { nonDbFields, calculateValue, form499R2 } from './autoCalculatedFieldHelper';
import { the499R2Fchar, theW2MFchar } from 'helpers/formConstants';
import { totalFederalTaxLiabilityField } from 'pages/withholdingForms/helpers/withholdingFormsHelper';

const AutoCalculatedField = ({
  floatingLabelText,
  isClone,
  name,
  form,
  payerInformation,
  formActions,
  taxEntityCode,
  value,
  dependentValue,
  onChange,
  ...otherProps
}) => {
  const initValue = () => {
    if (theW2MFchar === taxEntityCode) {
      return value || calculateValue(name, form, payerInformation, taxEntityCode, form, dependentValue);
    }
    return calculateValue(name, form, payerInformation, taxEntityCode, form);
  };

  const [calculatedValue, setCalculatedValue] = useState(() => initValue());
  const [previousValuesForm, setPreviousValuesForm] = useState(form);
  const isReadOnly = !(taxEntityCode === the499R2Fchar && name === form499R2.A06);

  useEffect(() => {
    setCalculatedValue(calculateValue(name, form, payerInformation, taxEntityCode, previousValuesForm, dependentValue));
    setPreviousValuesForm(form);
  }, [name, form, payerInformation]);

  useEffect(() => {
    if (theW2MFchar === taxEntityCode) {
      onChange && onChange(calculatedValue);
    } else if (!nonDbFields.includes(name)) {
      formActions.setFieldValue(name, calculatedValue, isClone, taxEntityCode);
    }
  }, [calculatedValue]);

  useEffect(() => {
    if (name !== totalFederalTaxLiabilityField) {
      formActions.overrideFormModified();
    }
  }, []);

  return (
    <TextFieldForm
      { ...otherProps }
      name={ name }
      value={ calculatedValue }
      readOnly={ isReadOnly }
      labelForm={ floatingLabelText }
      onChange={ () => {} }
    />
  );
};

AutoCalculatedField.propTypes = {
  floatingLabelText: PropTypes.string,
  isClone: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  payerInformation: PropTypes.object,
  readOnly: PropTypes.bool,
  taxEntityCode: PropTypes.string.isRequired,
  value: PropTypes.any,
  dependentValue: PropTypes.any,
  onChange: PropTypes.func
};

AutoCalculatedField.defaultProps = {
  value: null,
  dependentValue: null,
  onChange: undefined
};

export default AutoCalculatedField;

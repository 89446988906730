import filingStatusConstants from 'helpers/filingStatusConstants';

export const semiWeeklyFederalTaxLiabilityFields = [
  'JanuarySemiWeeklyFederalTaxLiability',
  'FebruarySemiWeeklyFederalTaxLiability',
  'MarchSemiWeeklyFederalTaxLiability',
  'AprilSemiWeeklyFederalTaxLiability',
  'MaySemiWeeklyFederalTaxLiability',
  'JuneSemiWeeklyFederalTaxLiability',
  'JulySemiWeeklyFederalTaxLiability',
  'AugustSemiWeeklyFederalTaxLiability',
  'SeptemberSemiWeeklyFederalTaxLiability',
  'OctoberSemiWeeklyFederalTaxLiability',
  'NovemberSemiWeeklyFederalTaxLiability',
  'DecemberSemiWeeklyFederalTaxLiability'
];

export const totalFederalTaxLiabilityField = 'TotalFederalTaxLiability';
export const monthlyScheduleDepositorFlagField = 'MonthlyScheduleDepositorFlag';
export const semiweeklyScheduleDepositorFlagField = 'SemiweeklyScheduleDepositorFlag';

export const outputFileTypeResponse = {
  FORM_1042_PDF: 1,
  FORM_1042_XML: 2,
  FORM_945_PDF: 3,
  FORM_945_XML: 4,
  IRS_RESPONSE: 8,
  FORM_945_A_PDF: 9
};

// Helper function to create the ThirdPartyDesignee and PaidPreparer objects
const createThirdPartyDesigneeAndPaidPreparer = form => {

  const determineDiscuss = (allow, notAllow) => (allow === 'X' ? 'X' : notAllow === 'X' ? '_' : null);

  const ThirdPartyDesignee = {
    Name: form.ThirdPartyDesigneeName,
    Phone: form.ThirdPartyDesigneePhone,
    PIN: form.ThirdPartyDesigneePIN,
    DiscussWithThirdPartyInd: determineDiscuss(form.AllowThirdPartyDesigneeFlag, form.NotAllowThirdPartyDesigneeFlag)
  };

  const PaidPreparer = {
    Name: form.PaidPreparerName,
    SelfEmployeed: form.PaidPreparerSelfEmployeed,
    SignDate: form.PaidPreparerDate,
    PTIN: form.PaidPreparerPTIN,
    FirmEIN: form.PaidPreparerFirmEIN,
    FirmName: form.PaidPreparerFirmName,
    FirmAddress: form.PaidPreparerFirmAddress,
    FirmCity: form.PaidPreparerFirmCity,
    FirmState: form.PaidPreparerFirmState,
    FirmZip: form.PaidPreparerFirmZip,
    FirmPhone: form.PaidPreparerFirmPhone
  };

  const isEmpty = obj => Object.values(obj).every(value => value == null || value === '');

  return {
    ThirdPartyDesignee: isEmpty(ThirdPartyDesignee) ? null : ThirdPartyDesignee,
    PaidPreparer: isEmpty(PaidPreparer) ? null : PaidPreparer
  };
};

export const prepare945FormForSaving = form => {
  // Create Form945X object for correction return
  const Form945X = form.ReturnStatus === filingStatusConstants.CORRECTION ? {
    AdjustedReturn: form.AdjustedReturn,
    Claim: form.Claim,
    UnderAndOverReported: form.UnderAndOverReported,
    Explanation: form.Explanation
  } : null;

  // Get ThirdPartyDesignee and PaidPreparer objects using the helper function
  const { ThirdPartyDesignee, PaidPreparer } = createThirdPartyDesigneeAndPaidPreparer(form);

  // Prepare the formToSave object
  const formToSave = {
    ...form,
    ...(Form945X && { Form945X }),
    PayerId: form.PAYERRECNO,
    ThirdPartyDesignee,
    PaidPreparer
  };

  // Handle semi-weekly tax liability fields
  semiWeeklyFederalTaxLiabilityFields.forEach(field => {
    formToSave[field] = Object.keys(formToSave)
      .filter(key => key.startsWith(`${field}_`))
      .reduce((acc, key) => ({
        ...acc,
        [key.replace(`${field}_`, '')]: formToSave[key]
      }), {});
  });

  // Clean up semi-weekly federal tax liability fields
  Object.keys(formToSave).forEach(key => {
    if (semiWeeklyFederalTaxLiabilityFields.some(field => key.includes(`${field}_`))) {
      delete formToSave[key];
    }
  });

  // Remove the total federal tax liability if neither flag is set
  if (!formToSave[monthlyScheduleDepositorFlagField] && !formToSave[semiweeklyScheduleDepositorFlagField]) {
    delete formToSave[totalFederalTaxLiabilityField];
  }

  // Remove obsolete fields
  const obsoleteFields = [
    'PAYERID',
    'PAYERRECNO',
    'RecordId',
    'NoneDepositorFlag',
    'AdjustedReturn',
    'Claim',
    'UnderAndOverReported',
    'Explanation',
    'Status',
    'ReturnStatus',
    'WFMarkAsCorrectionButton',
    'AllowThirdPartyDesigneeFlag',
    'NotAllowThirdPartyDesigneeFlag',
    'ThirdPartyDesigneeName',
    'ThirdPartyDesigneePhone',
    'ThirdPartyDesigneePIN',
    'PaidPreparerName',
    'PaidPreparerSelfEmployeed',
    'PaidPreparerDate',
    'PaidPreparerPTIN',
    'PaidPreparerFirmEIN',
    'PaidPreparerFirmName',
    'PaidPreparerFirmAddress',
    'PaidPreparerFirmCity',
    'PaidPreparerFirmState',
    'PaidPreparerFirmZip',
    'PaidPreparerFirmPhone'
  ];

  obsoleteFields.forEach(field => delete formToSave[field]);

  return { Form: formToSave };
};

export const prepare1042FormForSaving = form => {
  // Create Form1042Correction object for correction return
  const Form1042Correction = form.ReturnStatus === filingStatusConstants.CORRECTION ? {
    ReasonableCauseStatement: form.ReasonableCauseStatement
  } : null;

  // Get ThirdPartyDesignee and PaidPreparer objects
  const { ThirdPartyDesignee, PaidPreparer } = createThirdPartyDesigneeAndPaidPreparer(form);

  // Prepare the formToSave object
  const formToSave = {
    ...form,
    ...(Form1042Correction && { Form1042Correction }),
    PayerId: form.PAYERRECNO,
    ThirdPartyDesignee,
    PaidPreparer
  };

  // Handle Form1042ScheduleQs based on PaymentByQddFlag
  if (formToSave.PaymentByQddFlag !== 'X') {
    delete formToSave.Form1042ScheduleQs;
  } else {
    formToSave.Form1042ScheduleQs = formToSave.Form1042ScheduleQs?.map(({ id, ...rest }) => rest);
  }

  // Ensure numeric fields are string values
  formToSave.NumberOfFormsFilledElectronically = form.NumberOfFormsFilledElectronically ?? '0';
  formToSave.NumberOfFormsFilledOnPaper = form.NumberOfFormsFilledOnPaper ?? '0';

  // Remove obsolete fields
  const obsoleteFields = [
    'RecordId',
    'PAYERID',
    'PAYERRECNO',
    'ReasonableCauseStatement',
    'AllowThirdPartyDesigneeFlag',
    'NotAllowThirdPartyDesigneeFlag',
    'ThirdPartyDesigneeName',
    'ThirdPartyDesigneePhone',
    'ThirdPartyDesigneePIN',
    'PaidPreparerName',
    'PaidPreparerSelfEmployeed',
    'PaidPreparerDate',
    'PaidPreparerPTIN',
    'PaidPreparerFirmEIN',
    'PaidPreparerFirmName',
    'PaidPreparerFirmAddress',
    'PaidPreparerFirmCity',
    'PaidPreparerFirmState',
    'PaidPreparerFirmZip',
    'PaidPreparerFirmPhone'
  ];

  obsoleteFields.forEach(field => delete formToSave[field]);

  return { Form: formToSave };
};
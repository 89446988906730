import { createContext, useContext, useState } from 'react';

const getOrderIndicator = isAscending => {
  if (isAscending === null) {
    return '';
  }
  return isAscending ? '+' : '-';
};

const ColumnSortContext = createContext();

export const ColumnSortProvider = ({ children, initialSortColumn = { sortIndicator: 'ERRORVALIDATIONCOUNT', id: 'ERRORVALIDATIONCOUNT' }, initialIsAscending = false }) => {
  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const [isAscending, setIsAscending] = useState(initialIsAscending);

  const sortOrderIndicator = getOrderIndicator(isAscending);
  const sortColumnIndicator = sortColumn.sortIndicator;
  const sortColumnId = sortColumn.id;

  const onColumnSortChanged = newColumn => {
    const _isAscending = sortColumn.id === newColumn.id ? !isAscending : true;
    setSortColumn(newColumn);
    setIsAscending(_isAscending);
  };

  const resetColumnSort = () => {
    setSortColumn(initialSortColumn);
    setIsAscending(initialIsAscending);
  };

  return (
    <ColumnSortContext.Provider
      value={ {
        resetColumnSort,
        sortOrderIndicator,
        sortColumnIndicator,
        sortColumnId,
        onColumnSortChanged
      } }
    >
      { children }
    </ColumnSortContext.Provider>
  );
};

export const useColumnSortContext = () => {
  const context = useContext(ColumnSortContext);
  if (!context) {
    throw new Error('useColumnSort must be used within a ColumnSortProvider');
  }
  return context;
};

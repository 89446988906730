import {
  BE_PRINT_SERVICE_150000,
  BE_PRINT_SERVICE_150001,
  BE_PRINT_SERVICE_150002,
  BE_PRINT_SERVICE_150003,
  BE_PRINT_SERVICE_150004,
  BE_PRINT_SERVICE_150005,
  BE_TRANSMIT_SERVICE_110000,
  BE_TRANSMIT_SERVICE_110001,
  BE_TRANSMIT_SERVICE_110003,
  BE_TRANSMIT_SERVICE_120006,
  BE_TRANSMIT_SERVICE_120007,
  BE_TRANSMIT_SERVICE_120009,
  BE_TRANSMIT_SERVICE_120010,
  BE_TRANSMIT_SERVICE_120011,
  BE_TRANSMIT_SERVICE_120012,
  BE_TRANSMIT_SERVICE_120013,
  BE_TRANSMIT_SERVICE_120014,
  BE_TRANSMIT_SERVICE_130002,
  BE_TRANSMIT_SERVICE_130003,
  BE_TRANSMIT_SERVICE_130004,
  BE_TRANSMIT_SERVICE_130005,
  BE_TRANSMIT_SERVICE_130006,
  BE_TRANSMIT_SERVICE_130007,
  BE_TRANSMIT_SERVICE_130008,
  BE_TRANSMIT_SERVICE_130009,
  BE_TRANSMIT_SERVICE_130010,
  BE_TRANSMIT_SERVICE_130011,
  BE_TRANSMIT_SERVICE_130013,
  BE_TAX_ENTITY_SERVICE_100000,
  BE_TAX_ENTITY_SERVICE_100001,
  BE_TAX_ENTITY_SERVICE_100002,
  BE_TAX_ENTITY_SERVICE_100003,
  BE_TAX_ENTITY_SERVICE_100004,
  BE_TAX_ENTITY_SERVICE_120000,
  BE_TAX_ENTITY_SERVICE_120001,
  BE_TAX_ENTITY_SERVICE_120002,
  BE_TAX_ENTITY_SERVICE_120003,
  BE_TAX_ENTITY_SERVICE_120004,
  BE_TAX_ENTITY_SERVICE_120005,
  BE_TAX_ENTITY_SERVICE_120006,
  BE_TAX_ENTITY_SERVICE_120007,
  BE_TAX_ENTITY_SERVICE_120008,
  BE_TAX_ENTITY_SERVICE_120009,
  BE_TAX_ENTITY_SERVICE_120010,
  BE_TAX_ENTITY_SERVICE_120011,
  BE_TAX_ENTITY_SERVICE_120012,
  BE_TAX_ENTITY_SERVICE_120013,
  BE_TAX_ENTITY_SERVICE_120014,
  BE_TAX_ENTITY_SERVICE_120015,
  BE_TAX_ENTITY_SERVICE_120016,
  BE_TAX_ENTITY_SERVICE_120017,
  BE_TAX_ENTITY_SERVICE_120018,
  BE_TAX_ENTITY_SERVICE_120019,
  BE_TAX_ENTITY_SERVICE_120020,
  BE_TAX_ENTITY_SERVICE_120021,
  BE_TAX_ENTITY_SERVICE_120022,
  BE_TAX_ENTITY_SERVICE_120023,
  BE_TAX_ENTITY_SERVICE_120024,
  BE_TAX_ENTITY_SERVICE_120025,
  BE_TAX_ENTITY_SERVICE_120026,
  BE_TAX_ENTITY_SERVICE_130000,
  BE_TAX_ENTITY_SERVICE_130001,
  BE_TAX_ENTITY_SERVICE_130004,
  BE_TAX_ENTITY_SERVICE_130006,
  BE_TAX_ENTITY_SERVICE_130007,
  BE_TAX_ENTITY_SERVICE_130008,
  BE_TAX_ENTITY_SERVICE_140000,
  BE_TAX_ENTITY_SERVICE_140001,
  BE_TAX_ENTITY_SERVICE_140002,
  BE_TAX_ENTITY_SERVICE_140003,
  BE_TAX_ENTITY_SERVICE_150000,
  BE_TAX_ENTITY_SERVICE_150001,
  BE_TAX_ENTITY_SERVICE_150002,
  BE_TAX_ENTITY_SERVICE_V2_100106,
  BE_TAX_ENTITY_SERVICE_V2_100109,
  BE_TAX_ENTITY_SERVICE_V2_100121,
  BE_TAX_ENTITY_SERVICE_V2_100503,
  BE_TAX_IDENTITY_100002,
  BE_TAX_IDENTITY_100004,
  BE_TAX_IDENTITY_100005,
  BE_TAX_IDENTITY_100006,
  BE_TAX_IDENTITY_100009,
  BE_TAX_IDENTITY_100010,
  BE_TAX_IDENTITY_100011,
  BE_TAX_IDENTITY_100012,
  BE_TAX_IDENTITY_100013,
  BE_TAX_IDENTITY_100014,
  BE_TAX_IDENTITY_100017,
  BE_TAX_IDENTITY_100018,
  BE_TAX_IDENTITY_100019,
  BE_TAX_IDENTITY_100022,
  BE_TAX_IDENTITY_100023,
  BE_TAX_IDENTITY_100024,
  BE_TAX_IDENTITY_100025,
  BE_TAX_IDENTITY_100026,
  BE_TAX_IDENTITY_100027,
  BE_TAX_IDENTITY_100028,
  BE_TAX_IDENTITY_100029,
  BE_TAX_IDENTITY_100030,
  BE_TAX_IDENTITY_100031,
  BE_TAX_IDENTITY_100034,
  BE_TAX_IDENTITY_100038,
  BE_TAX_IDENTITY_100042,
  BE_TAX_IDENTITY_100043,
  BE_TAX_IDENTITY_100046,
  BE_TAX_IDENTITY_100055,
  BE_TAX_IDENTITY_100056,
  BE_TAX_IDENTITY_100057,
  BE_TAX_IDENTITY_100058,
  BE_TAX_IDENTITY_100062,
  BE_TAX_IDENTITY_100067,
  BE_TAX_IDENTITY_100068,
  BE_TAX_IDENTITY_100070,
  BE_TAX_IDENTITY_100071,
  BE_TAX_IDENTITY_100072,
  BE_TAX_IDENTITY_100073,
  BE_TAX_IDENTITY_100074,
  BE_TAX_IDENTITY_100075,
  BE_TAX_IDENTITY_100076,
  BE_TAX_IDENTITY_100077,
  BE_TAX_IDENTITY_100078,
  BE_TAX_IDENTITY_100079,
  BE_TAX_IDENTITY_100080,
  BE_TAX_IDENTITY_100088,
  BE_TAX_IDENTITY_100092,
  BE_TAX_IDENTITY_100093,
  BE_TAX_IDENTITY_100094,
  BE_TAX_IDENTITY_100096,
  BE_TAX_IDENTITY_100097,
  BE_TAX_IDENTITY_100098,
  BE_TAX_IDENTITY_100113,
  BE_TAX_IDENTITY_VERIFICATION_100007,
  BE_TAX_IDENTITY_VERIFICATION_100011,
  BE_IMPORTV2_130104,
  BE_IMPORT_SERVICE_100001,
  BE_IMPORT_SERVICE_100002,
  BE_IMPORT_SERVICE_100004,
  BE_IMPORT_SERVICE_100005,
  BE_REPORT_SERVICE_100000,
  BE_REPORT_SERVICE_100001,
  BE_REPORT_SERVICE_100002,
  BE_REPORT_SERVICE_101000,
  BE_REPORT_SERVICE_101100,
  BE_REPORT_SERVICE_101200,
  BE_REPORT_SERVICE_101201,
  BE_REPORT_SERVICE_101202,
  BE_REPORT_SERVICE_101300,
  BE_REPORT_SERVICE_101301,
  BE_REPORT_SERVICE_101302,
  BE_REPORT_SERVICE_101303,
  BE_REPORT_SERVICE_101304,
  BE_REPORT_SERVICE_101305,
  BE_REPORT_SERVICE_101306,
  BE_FILE_MANAGER_SERVICE_100012,
  FE_TRANSMIT_001,
  FE_CORP_TRANSMIT_001,
  FE_CORP_TRANSMIT_002,
  FE_CORP_TRANSMIT_003,
  FE_PRINT_001,
  FE_ENT_TRANSMIT_001,
  FE_ENT_TRANSMIT_002,
  FE_ENT_TRANSMIT_003,
  FE_ENT_TRANSMIT_004,
  FE_ENT_TRANSMIT_005,
  FE_ENT_TRANSMIT_006,
  FE_ENT_TRANSMIT_007,
  FE_ENT_TRANSMIT_008,
  FE_ENT_TRANSMIT_009,
  FE_ENT_TRANSMIT_010,
  FE_ENT_TRANSMIT_011,
  FE_ENT_PRINT_001,
  FE_ENT_PRINT_002,
  FE_ENT_PRINT_003,
  FE_ENT_PRINT_004,
  FE_ENT_PRINT_005,
  FE_ENT_TAXIDENT_001,
  FE_ENT_TAXIDENT_002,
  FE_ENT_TAXIDENT_003,
  FE_ENT_TAXIDENT_004,
  FE_ENT_TAXIDENT_005,
  FE_ENT_TAXIDENT_006,
  FE_ENT_TAXIDENT_007,
  FE_ENT_TAXIDENT_008,
  FE_ENT_FILEMANAGER_001,
  FE_ENT_PRINT_NOTE_001,
  FE_FEATURES_001,
  FE_FEATURES_002,
  FE_IMPORT_001,
  FE_IMPORT_002,
  FE_IMPORT_003,
  FE_IMPORT_004,
  FE_IMPORT_005,
  FE_IMPORT_006,
  FE_REPORT_001,
  FE_REPORT_002,
  FE_FILEMANAGER_001,
  FE_BACKUP_WITHHOLDING_001,
  FE_FECHING_RECORDS_001,
  FE_NEW_TAX_YEAR_001,
  FE_NEW_TAX_YEAR_002
} from './errorMessageConstants';

export const backendErrorMessages = {
  // backend will provide error codes, mapping between error code and user-exposed message goes here
  [BE_PRINT_SERVICE_150000]: 'Marking form for reprint failed - form already marked for reprint.',
  [BE_PRINT_SERVICE_150001]: 'Cancelling reprint failed - no pending reprint was found for the given form.',
  [BE_PRINT_SERVICE_150002]: 'Marking form for reprint failed.',
  [BE_PRINT_SERVICE_150003]: 'Reprint form generation failed.',
  [BE_PRINT_SERVICE_150004]: 'Something went wrong migrating your data. Your print notes were already migrated.',
  [BE_PRINT_SERVICE_150005]: 'Something went wrong migrating your data.',
  [BE_TRANSMIT_SERVICE_110000]: 'Something went wrong while creating the new transmittal. Please try again.',
  [BE_TRANSMIT_SERVICE_110001]: 'Something went wrong while retrieving the cover page. Please try again.',
  [BE_TRANSMIT_SERVICE_110003]: 'Something went wrong while retrieving the files. Please try again.',
  [BE_TRANSMIT_SERVICE_120006]: 'Something went wrong while retrieving transmittal information. Please try again.',
  [BE_TRANSMIT_SERVICE_120007]: 'Something went wrong while retrieving transmittal information. Please try again.',
  [BE_TRANSMIT_SERVICE_120009]: 'Cannot approve transmittal since all files are not yet ready for approval. Please contact support.',
  [BE_TRANSMIT_SERVICE_120010]: 'Something went wrong while retrieving control totals. Please try again.',
  [BE_TRANSMIT_SERVICE_120011]: 'Something went wrong while retrieving control totals. Please try again.',
  [BE_TRANSMIT_SERVICE_120012]: 'Something went wrong while updating confirmation control number. Please try again.',
  [BE_TRANSMIT_SERVICE_120013]: 'File not found.',
  [BE_TRANSMIT_SERVICE_120014]: 'Multiple files with the same name were found.',
  [BE_TRANSMIT_SERVICE_130002]: 'The sum of ‘Amount of overpayment to be applied as credit’ and ‘Amount of overpayment to be refunded’ must equal ‘Overpayment’.',
  [BE_TRANSMIT_SERVICE_130003]: 'NAICS Code required.',
  [BE_TRANSMIT_SERVICE_130004]: 'Invalid Form Type.',
  [BE_TRANSMIT_SERVICE_130005]: 'FilingCycle must be M or B or Q or Y.',
  [BE_TRANSMIT_SERVICE_130006]: 'CombinedFederalState must be Y or N.',
  [BE_TRANSMIT_SERVICE_130007]: 'The rest of ‘Tax withheld on W-2s’ minus ‘Withholding payments’ must be equal to ‘Remaining tax due or refund’.',
  [BE_TRANSMIT_SERVICE_130008]: 'The sum of ‘Remaining tax due or refund’ plus ‘Penalty on balance due’ plus ‘Interest on balance due’ must be equal to ‘Total Amount due or refund’.',
  [BE_TRANSMIT_SERVICE_130009]: 'The sum of ‘Number of W-2s’ plus ‘Number of 1099s’ must be equal to ‘Total number of W-2 and 1099’.',
  [BE_TRANSMIT_SERVICE_130010]: 'The sum of ‘Total Amount due or refund’ plus ‘Penalty for late filing’ must be equal to ‘Total due or refund’.',
  [BE_TRANSMIT_SERVICE_130011]: 'Invalid Tax Year.',
  [BE_TRANSMIT_SERVICE_130013]: 'Form type not supported for the selected tax year.',
  [BE_TAX_ENTITY_SERVICE_100000]: 'Something went wrong while retrieving validations. Please try again.',
  [BE_TAX_ENTITY_SERVICE_100001]: 'Something went wrong while performing validations. Please check your entries and try again.',
  [BE_TAX_ENTITY_SERVICE_100002]: 'Something went wrong while performing validations. Please check your entries and try again.',
  [BE_TAX_ENTITY_SERVICE_100003]: 'Something went wrong while performing validations. Please check your entries and try again.',
  [BE_TAX_ENTITY_SERVICE_100004]: 'Something went wrong while performing validations. Please check your entries and try again.',
  [BE_TAX_ENTITY_SERVICE_120000]: 'Reset is not allowed for the selected entity types.',
  [BE_TAX_ENTITY_SERVICE_120001]: 'Something went wrong while resetting entities.',
  [BE_TAX_ENTITY_SERVICE_120002]: 'Record was not found.',
  [BE_TAX_ENTITY_SERVICE_120003]: 'Something went wrong while retrieving record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120004]: 'Record cannot be deleted because it was printed and transmitted.',
  [BE_TAX_ENTITY_SERVICE_120005]: 'Record cannot be deleted because it was printed.',
  [BE_TAX_ENTITY_SERVICE_120006]: 'Record cannot be deleted because it was transmitted.',
  [BE_TAX_ENTITY_SERVICE_120007]: 'Something went wrong while deleting record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120008]: 'Something went wrong while deleting record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120009]: 'Something went wrong while deleting record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120010]: 'A field required to be unique is used by another record. Please check your entries and try again.',
  [BE_TAX_ENTITY_SERVICE_120011]: 'A required field is missing. Please check your entries and try again.',
  [BE_TAX_ENTITY_SERVICE_120012]: 'Something went wrong while saving record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120013]: 'Something went wrong while saving record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120014]: 'Record was changed by another user or process. Please refresh the form and try again.',
  [BE_TAX_ENTITY_SERVICE_120015]: 'Something went wrong while saving record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120016]: 'Something went wrong while saving record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120017]: 'Something went wrong while saving record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120018]: 'Something went wrong while saving record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120019]: 'Something went wrong while marking Forms as corrected. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120020]: 'Invalid search field selected. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120021]: 'Search value does not meet the minimum length requirement. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120022]: 'Search value contains invalid characters. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120023]: 'Something went wrong while saving record. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120024]: 'Something went wrong while counting associated Forms. Please try again.',
  [BE_TAX_ENTITY_SERVICE_120025]: 'Record cannot be deleted because it has Forms that have been printed or transmitted.',
  [BE_TAX_ENTITY_SERVICE_120026]: 'Something went wrong while checking if the record can be deleted. Please try again.',
  [BE_TAX_ENTITY_SERVICE_130000]: 'Something went wrong while saving Payer. Please try again.',
  [BE_TAX_ENTITY_SERVICE_130001]: 'Something went wrong while saving Payer. Please try again.',
  [BE_TAX_ENTITY_SERVICE_130004]: 'Something went wrong while retrieving Payers that are creating issues for Forms. Please try again.',
  [BE_TAX_ENTITY_SERVICE_130006]: 'Something went wrong while retrieving Payer localities. Please try again.',
  [BE_TAX_ENTITY_SERVICE_130007]: 'Something went wrong while updating Payer localities. Please try again.',
  [BE_TAX_ENTITY_SERVICE_130008]: 'Payer cannot be updated because it has 1042-S forms that were already transmitted.',
  [BE_TAX_ENTITY_SERVICE_140000]: 'Payee cannot be updated because it has Forms that were already transmitted.',
  [BE_TAX_ENTITY_SERVICE_140001]: 'Something went wrong while updating Payees. Please try again.',
  [BE_TAX_ENTITY_SERVICE_140002]: 'Something went wrong while retrieving Payees for Solicitation.',
  [BE_TAX_ENTITY_SERVICE_140003]: 'Something went wrong while saving Payee. Please try again.',
  [BE_TAX_ENTITY_SERVICE_150000]: 'Access to Tax Identity Management is not configured for this enviornment.',
  [BE_TAX_ENTITY_SERVICE_150001]: 'Form was already transmitted.',
  [BE_TAX_ENTITY_SERVICE_150002]: 'The user is not allowed to perform that action.',
  [BE_TAX_ENTITY_SERVICE_V2_100106]: 'A W-2 form already exists for this Payer and Recipient combination. Please update the existing form rather than creating a new one.',
  [BE_TAX_ENTITY_SERVICE_V2_100109]: 'Form was already transmitted.',
  [BE_TAX_ENTITY_SERVICE_V2_100503]: 'Something went wrong while performing validations. Please check your entries and try again.',
  [BE_TAX_ENTITY_SERVICE_V2_100121]: 'The user is not allowed to perform that action.',
  [BE_TAX_IDENTITY_100002]: 'This environment is not configured for Tax Identity Management Solicitation access.',
  [BE_TAX_IDENTITY_100004]: 'Invalid Solicitation Source. Please try again.',
  [BE_TAX_IDENTITY_100005]: 'Something went wrong while creating the solicitation. Please try again.',
  [BE_TAX_IDENTITY_100006]: 'Something went wrong while creating the solicitation. Please try again.',
  [BE_TAX_IDENTITY_100009]: 'Notice Date is required. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100010]: 'Response Date is required. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100011]: 'Response Date must be in the future. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100012]: 'A required field is missing. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100013]: 'Invalid Notice Type. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100014]: 'Invalid Tax Year. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100017]: 'Something went wrong while updating the solicitation. Please try again.',
  [BE_TAX_IDENTITY_100018]: 'Something went wrong while accessing this solicitation. Please try again.',
  [BE_TAX_IDENTITY_100019]: 'Something went wrong while accessing this solicitation. Please try again.',
  [BE_TAX_IDENTITY_100022]: 'Invalid Form Type. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100023]: 'Something went wrong while retrieving Total Recipients. Please try again.',
  [BE_TAX_IDENTITY_100024]: 'Invalid Transmitter Control Code. Please check your entries and try again',
  [BE_TAX_IDENTITY_100025]: 'Invalid TIN. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100026]: 'Invalid Name. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100027]: 'Invalid Name 2. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100028]: 'Invalid Address. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100029]: 'Invalid City. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100030]: 'Invalid State. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100031]: 'Invalid Zip. Please check your entries and try again.',
  [BE_TAX_IDENTITY_100034]: 'Cannot create job. Please contact support.',
  [BE_TAX_IDENTITY_100038]: 'Cannot find notice record. Please reload and try again.',
  [BE_TAX_IDENTITY_100042]: 'Something went wrong while trying to get settings. Please try again.',
  [BE_TAX_IDENTITY_100043]: 'Something went wrong while trying to save settings. Please try again.',
  [BE_TAX_IDENTITY_100046]: 'Ungrouped recipient records were found. Please resolve them on the Recipient Grouping page under Tax Identity Management.',
  [BE_TAX_IDENTITY_100055]: 'Invalid solicitation response state.',
  [BE_TAX_IDENTITY_100056]: 'Cannot mark response as unusable, file already has saved processing states.',
  [BE_TAX_IDENTITY_100057]: 'Something went wrong while saving the response. Please try again.',
  [BE_TAX_IDENTITY_100058]: 'Invalid solicitation response payload. Please contact support.',
  [BE_TAX_IDENTITY_100062]: 'Response cannot be saved, file is already in complete or archive state.',
  [BE_TAX_IDENTITY_100067]: 'This environment is not configured for Tax Identity Management Response access.',
  [BE_TAX_IDENTITY_100068]: 'Cannot save response. Please try again.',
  [BE_TAX_IDENTITY_100070]: 'Cannot approve solicitation since it is not yet ready for approval. Please contact support.',
  [BE_TAX_IDENTITY_100071]: 'Cannot approve solicitation since all files are not yet ready for approval. Please contact support.',
  [BE_TAX_IDENTITY_100072]: 'Something went wrong while approving solicitation. Please try again.',
  [BE_TAX_IDENTITY_100073]: 'Cannot save archive response. Please try again.',
  [BE_TAX_IDENTITY_100074]: 'Cannot save needs review response. Please try again.',
  [BE_TAX_IDENTITY_100075]: 'Cannot save completed response. Please try again.',
  [BE_TAX_IDENTITY_100076]: 'Something went wrong trying to recalculate all tax identity statuses. Please try again.',
  [BE_TAX_IDENTITY_100077]: 'Cannot save undeliverable response. Please try again.',
  [BE_TAX_IDENTITY_100078]: 'Cannot create backup withholding records report. Please try again.',
  [BE_TAX_IDENTITY_100079]: 'Cannot create backup withholding records report. Please contact support.',
  [BE_TAX_IDENTITY_100080]: 'User tried to enqueue backup withholding job for solication that does not support it. Please contact support.',
  [BE_TAX_IDENTITY_100088]: 'Something went wrong trying to calculate all tax identity backup withholding statuses. Please try again.',
  [BE_TAX_IDENTITY_100092]: 'Failed to add tax identity aliases to solicitation.',
  [BE_TAX_IDENTITY_100093]: 'Failed to get tax identity aliases for solicitation.',
  [BE_TAX_IDENTITY_100094]: 'Failed to remove tax identity aliases from solicitation.',
  [BE_TAX_IDENTITY_100096]: 'Something went wrong while canceling the solicitation. Please try again.',
  [BE_TAX_IDENTITY_100097]: 'Cannot cancel solicitation since it is not in Prepare Data step. Please contact support.',
  [BE_TAX_IDENTITY_100098]: 'Something went wrong while canceling the solicitation. Please try again.',
  [BE_TAX_IDENTITY_100113]: 'A recipient with this info already exists but is ungrouped, so it cannot be solicited. Please resolve this on the Recipient Grouping page before continuing.',
  [BE_TAX_IDENTITY_VERIFICATION_100007]: 'The IRS allows for a maximum of 40 characters in the Name field for RTT.',
  [BE_TAX_IDENTITY_VERIFICATION_100011]: 'Bulk TIN Matching is not allowed in TEST contexts.',
  [BE_IMPORTV2_130104]: 'Invalid file format. Please check the file and try again.',
  [BE_IMPORT_SERVICE_100001]: 'Something went wrong trying to check if this import can be backed out.  Please try again or contact Support.',
  [BE_IMPORT_SERVICE_100002]: 'Something went wrong trying to back out this import. Please try again or contact Support.',
  [BE_IMPORT_SERVICE_100004]: 'Failed to enqueue this import job. Map doesn\'t exist.',
  [BE_IMPORT_SERVICE_100005]: 'Failed to enqueue this import job. Layout doesn\'t exist.',
  [BE_REPORT_SERVICE_100000]: 'Export failed: Uri requested for report generation is not approved.',
  [BE_REPORT_SERVICE_100001]: 'Export failed: Dataset contains empty column name.',
  [BE_REPORT_SERVICE_100002]: 'Export failed: Report failed to generate.',
  [BE_REPORT_SERVICE_101000]: 'Something went wrong. Please try again or contact Support.',
  [BE_REPORT_SERVICE_101100]: 'The report template was not found. Please try again or contact Support.',
  [BE_REPORT_SERVICE_101200]: 'A conflict was encountered with the report template\'s data. Please try again or contact Support.',
  [BE_REPORT_SERVICE_101201]: 'The version of the report template being modified is not the most recent. Please use the most recent version.',
  [BE_REPORT_SERVICE_101202]: 'The given report name is already in use. Please give another.',
  [BE_REPORT_SERVICE_101300]: 'Something went wrong while saving the report template. Please try again.',
  [BE_REPORT_SERVICE_101301]: 'Something went wrong while deleting the report template. Please try again.',
  [BE_REPORT_SERVICE_101302]: 'Something went wrong while retrieving the report template. Please try again.',
  [BE_REPORT_SERVICE_101303]: 'Something went wrong while searching for report templates. Please try again.',
  [BE_REPORT_SERVICE_101304]: 'Something went wrong while validating the settings for the report template. Please try again.',
  [BE_REPORT_SERVICE_101305]: 'Something went wrong while trying to update the report template. Please try again.',
  [BE_REPORT_SERVICE_101306]: 'Something went wrong while searching report history. Please try again.',
  [BE_FILE_MANAGER_SERVICE_100012]: 'Uploaded files must have unique names.'
};

export const fallbackErrorMessages = {
  // these are optional fallback messages that can be defined on the frontend
  [FE_TRANSMIT_001]: 'Something went wrong while retrieving recent transmits. Please try again.',
  [FE_CORP_TRANSMIT_001]: 'There was an issue generating the transmittal.',
  [FE_CORP_TRANSMIT_002]: 'Something went wrong while retrieving filters. Please try again.',
  [FE_PRINT_001]: 'Something went wrong while retrieving print information. Please try again.',
  [FE_CORP_TRANSMIT_003]: 'Something went wrong while canceling batch file. Please try again.',
  [FE_ENT_TRANSMIT_001]: 'Something went wrong while retrieving default settings. Please try again.',
  [FE_ENT_TRANSMIT_002]: 'Something went wrong while retrieving transmittal information. Please try again.',
  [FE_ENT_TRANSMIT_003]: 'Unable to save confirmation.',
  [FE_ENT_TRANSMIT_004]: 'Unable to delete confirmation.',
  [FE_ENT_TRANSMIT_005]: 'Cover Letter not found.',
  [FE_ENT_TRANSMIT_006]: 'Download failed. Please try again.',
  [FE_ENT_TRANSMIT_007]: 'Something went wrong while creating the transmittal. Please try again.',
  [FE_ENT_TRANSMIT_008]: 'Unsupported state/form combination(s). Please try again.',
  [FE_ENT_TRANSMIT_009]: 'Unable to update transmittal state.',
  [FE_ENT_TRANSMIT_010]: 'Something went wrong while retrieving transmittal control totals. Please try again.',
  [FE_ENT_TRANSMIT_011]: 'Something went wrong while retrieving the transmitters. Please try again.',
  [FE_ENT_PRINT_001]: 'Something went wrong while retrieving print information. Please try again.',
  [FE_ENT_PRINT_002]: 'Something went wrong. This template could not be deleted.',
  [FE_ENT_PRINT_003]: 'Download failed. Please try again.',
  [FE_ENT_PRINT_004]: 'There was an issue retrieving print card information. Redirecting to print tracker.',
  [FE_ENT_PRINT_005]: 'There was an issue retrieving the duplex setting default value.',
  [FE_ENT_TAXIDENT_001]: 'Something went wrong while retrieving your solicitation data. Please try again.',
  [FE_ENT_TAXIDENT_002]: 'Something went wrong while creating the Notice Recipient. Please try again.',
  [FE_ENT_TAXIDENT_003]: 'Something went wrong while retrieving Notice Recipients. Please try again.',
  [FE_ENT_TAXIDENT_004]: 'Something went wrong while adding the Notice Recipient. Please try again.',
  [FE_ENT_TAXIDENT_005]: 'Something went wrong while retrieving the new recipient total. Please refresh the page.',
  [FE_ENT_TAXIDENT_006]: 'Something went wrong while removing the notice record. Please try again.',
  [FE_ENT_TAXIDENT_007]: 'Something went wrong while retrieving your recipients counts data. Please try again.',
  [FE_ENT_TAXIDENT_008]: 'Previous attempt to generate solicitations failed. Please contact support.',
  [FE_ENT_PRINT_NOTE_001]: 'Something went wrong while retrieving print notes. Please try again.',
  [FE_FEATURES_001]: 'Something went wrong while retrieving tax forms. Please try again.',
  [FE_FEATURES_002]: 'Something went wrong while retrieving states and tax forms. Please try again.',
  [FE_IMPORT_001]: 'Unable to save layout.',
  [FE_IMPORT_002]: 'Error while retrieving rows file data.',
  [FE_IMPORT_003]: 'There was an issue finding your previous map. Please select a new one.',
  [FE_IMPORT_004]: 'The import process failed. Please contact support.',
  [FE_IMPORT_005]: 'There was an issue loading maps. Please contact support.',
  [FE_IMPORT_006]: 'Could not retrieve import file name.',
  [FE_ENT_FILEMANAGER_001]: 'There was a problem getting the pdf. Please contact support.',
  [FE_FILEMANAGER_001]: 'There was a problem getting the file. Please contact support.',
  [FE_REPORT_001]: 'Export failed.',
  [FE_REPORT_002]: 'Something went wrong while generating the report. Please try again.',
  [FE_BACKUP_WITHHOLDING_001]: 'Your files were approved, but Backup Withholding process failed. Please use Admin Screen to fix.',
  [FE_FECHING_RECORDS_001]: 'Something went wrong while retrieving records. Please try again.',
  [FE_NEW_TAX_YEAR_001]: 'Something went wrong migrating your data.',
  [FE_NEW_TAX_YEAR_002]: 'Something went wrong creating the new tax year.'
};

// this is the message that will be used if no arguments are provided to getErrorMessage
// or error codes are not found in backendErrorMessages or fallbackErrorMessages
export const defaultErrorMessage = 'Something is wrong. Please try again.';

//this is a specific message that can be dynamic from the BE (e.g data entry error validations)
//we also use the supportData for other things that we don't wanna show to the user, these ones should not go in this array
export const supportDataMessages = [
  BE_TAX_IDENTITY_100075
];

export const getErrorMessage = (error, fallbackErrorCode) => {
  const backendErrorMessage = backendErrorMessages[error?.data?.code]
    ? <>
      { backendErrorMessages[error.data.code] }
      { supportDataMessages.includes(error.data.code) ? <><br />{ error.data.supportData }</> : '' }
      { error.data.id ? <><br />Support Code: { error.data.id }</> : '' }
    </>
    : error?.data?.message
      ? error.data.message
      : false;

  return backendErrorMessage || fallbackErrorMessages[fallbackErrorCode] || defaultErrorMessage;
};

import actionTypes from './PrintCardConstants';
import { corpPrintStates } from 'helpers/enums';

const initialStore = {
  cartData: {
    [corpPrintStates.READY_TO_PRINT]: [],
    [corpPrintStates.AWATING_APPROVAL]: [],
    CorrectedForms: []
  },
  fetchingReadyToPrint: false,
  fetchingAwaitingApproval: false,
  fetchingCorrectedForms: false,
  breakPoint: { breakPoint: 1250 }
};

export default function PrintCartReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.SET_FETCHING_CARTDATA_PRINT:
      if (action.filingStatus) {
        return {
          ...state,
          fetchingCorrectedForms: true
        };
      }
      return {
        ...state,
        [`fetching${action.state}`]: true
      };

    case actionTypes.FETCH_CARTDATA_SUCCESS_PRINT:
      if (action.filingStatus) {
        return {
          ...state,
          cartData: {
            ...state.cartData,
            CorrectedForms: action.data.data
          },
          fetchingCorrectedForms: false
        };
      }
      return {
        ...state,
        cartData: {
          ...state.cartData,
          [action.state]: action.data.data
        },
        [`fetching${action.state}`]: false
      };

    case actionTypes.FETCH_CARTDATA_FAILURE_PRINT:
      return { ...state, fetchingCartData: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
